import axios from "@/api/axios";

//Получение информации для составления стратового протокола
const aboutCompStartList = () => {
  return axios.get(``);
};
const getNominationsList = (competitionId) => {
  return axios.get(`/get/performedType/individual/${competitionId}`);
};
const getNominationGroupList = (competitionId) => {
  return axios.get(`/get/performedType/group/${competitionId}`);
};
const postAllInfoAboutStartList = (allInfo) => {
  return axios.post(`/create/protocol`, allInfo);
};

export default {
  aboutCompStartList,
  getNominationsList,
  postAllInfoAboutStartList,
  getNominationGroupList,
};
