<template>
  <div class="creation__container">
    <div class="creation__collection-info">
      <div class="creation__input">
        <p class="label-text-info" for="title">Название вашего мероприятия:</p>
        <input
          type="text"
          class="input-text-index"
          placeholder="Например: Кубок Города Новосибирска"
          name="title"
          @blur="handleBlur"
          v-model="title"
        />
      </div>
      <div class="creation__input">
        <p class="label-text-info" for="place">
          Место проведения соревнований:
        </p>
        <input
          type="text"
          class="input-text-index"
          placeholder="Например: Дворец спорта НГТУ"
          name="place"
          @blur="handleBlur"
          v-model="place"
        />
      </div>
      <div class="creation__input-row">
        <div class="creation__input-row-block">
          <p class="label-text-info" for="mainJudge">
            Главный судья соревнований (ФИО) :
          </p>
          <input
            type="text"
            class="input-text-index"
            placeholder="Иванов Иван Васильевич"
            name="mainJudge"
            @blur="handleBlur"
            v-model="mainJudge"
          />
        </div>
        <div class="">
          <p class="label-text-info" for="judgeCategory">Судейская категория</p>
          <select
            name="judgeCategory"
            class="input-text-index"
            @blur="handleBlur"
            v-model="judgeCategory"
          >
            <option value="б/к" selected>б/к</option>
            <option value="3 категория" selected>3 категория</option>
            <option value="2 категория">2 категория</option>
            <option value="1 категория">1 категория</option>
            <option value="ССВК">ССВК</option>
          </select>
        </div>
      </div>
      <div class="creation__input-row">
        <div class="creation__input-row-block">
          <p class="label-text-info" for="chiefSecretary">
            Главный секретарь соревнований (ФИО) :
          </p>
          <input
            type="text"
            class="input-text-index"
            placeholder="Иванов Иван Васильевич"
            name="chiefSecretary"
            @blur="handleBlur"
            v-model="chiefSecretary"
          />
        </div>
        <div class="">
          <p class="label-text-info" for="chiefCategory">Судейская категория</p>
          <select
            name="chiefCategory"
            class="input-text-index"
            @blur="handleBlur"
            v-model="chiefCategory"
          >
            <option value="б/к" selected>б/к</option>
            <option value="3 категория">3 категория</option>
            <option value="2 категория">2 категория</option>
            <option value="1 категория">1 категория</option>
            <option value="ССВК">ССВК</option>
          </select>
        </div>
      </div>
      <div class="creation__input-row">
        <div class="creation__column">
          <p class="label-text-info" for="fromDate">
            Даты проведения соревнований:
          </p>
          <div class="creation__row">
            <input
              type="date"
              class="input-text-index"
              placeholder="Время, место"
              name="fromDate"
              @blur="handleBlur"
              v-model="fromDate"
            />
            <p class="label-text-info" for="toDate">до</p>
            <div class="margin-left15">
              <input
                type="date"
                class="input-text-index"
                placeholder="Время, место"
                v-model="toDate"
                @blur="handleBlur"
                name="toDate"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="creation__input-date">
        <p class="label-text-info" for="applicationsBefore">
          Подача заявок до:
        </p>
        <input
          type="date"
          class="input-text-index"
          placeholder="Время, место"
          v-model="applicationsBefore"
          @blur="handleBlur"
          name="applicationsBefore"
        />
      </div>
    </div>
    <div class="creation__btn">
      <button class="button" @click="switchTabsClass">Далее</button>
    </div>
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registrationComp";

export default {
  name: "AppIndexInfoAboutCompetition",
  data() {
    return {
      title: "",
      place: "",
      mainJudge: "",
      judgeCategory: "",
      chiefCategory: "",
      chiefSecretary: "",
      toDate: "",
      fromDate: "",
      applicationsBefore: "",
      infoCompetition: "",
    };
  },
  // props: {
  //   id: {
  //     type: String && Number,
  //     default() {
  //       return {};
  //     },
  //   },
  // },
  props: ["id"],
  methods: {
    handleBlur(e) {
      this.infoCompetition = {
        competitionId: this.id,
        [e.target.name]: e.target.value,
      };

      this.$store
        .dispatch(actionTypes.competitionsRegFirst, this.infoCompetition)
        .then(() => {
          this.infoCompetition = {};
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    switchTabsClass() {
      this.$emit("switchTabsClass", "individual");
    },
  },
};
</script>

<style></style>
