<template>
  <div class="">
    <!-- :class="{ 'is-open': isOpen }" -->
    <div class="accordion">
      <div class="accordion-header-check" @click="toggleAccordion">
        {{ selected.name }}
        <p class="selected-text">Заявленная категория</p>
      </div>

      <!-- <div class="accordion-body items" ref="body">
        <div
          v-for="(option, i) of individualCategories"
          :key="i"
          @click="safeSelect(option), toggleAccordion()"
        >
          {{ option.name }}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    checkSelect: {
      type: Object,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    ...mapState({
      // individualCategories: (state) => state.registration.individualCategories,
    }),
    selected() {
      return this.checkSelect;
    },
  },
  methods: {
    safeSelect(option) {
      this.selected = option;
      this.$emit("input", option);
    },

    toggleAccordion() {
      // this.isOpen = !this.isOpen;
      // if (this.isOpen) {
      //   this.animateOpen();
      // } else {
      //   this.animateClose();
      // }
    },
    animateOpen() {
      const body = this.$refs.body;
      const height = body.scrollHeight;
      body.style.height = "0";
      setTimeout(() => {
        body.style.height = `${height}px`;
      }, 5);
    },
    animateClose() {
      const body = this.$refs.body;
      body.style.height = `${body.scrollHeight}px`;
      setTimeout(() => {
        body.style.height = "0";
      }, 5);
    },
  },
};
</script>

<style></style>
