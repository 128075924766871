<template>
  <tr>
    <td class="tb__checkbox" @change="onChange">
      <input
        :value="item_coach.id"
        class="select__person"
        type="checkbox"
        v-model="checkedCoach"
      />
    </td>
    <td class="tb__name">
      {{ item_coach.surname }} {{ item_coach.coachName }}
      {{ item_coach.patronymic }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "AppItemTableCoach",
  data() {
    return {
      checkedP: false,
    };
  },
  props: {
    item_coach: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  computed: {
    checkedCoach: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(val, "val");
        this.checkedP = val;
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheck");
    },
  },
};
</script>

<style></style>
