<template>
  <div class="mandated">
    <Header message="Мандатная (Админ)" />
    <BreadCrumps/>
    <div class="main__container">
      <div class="competition">
        <item-verification-competition
          v-for="itemVerComp in this.allCompetition"
          :key="itemVerComp.id"
          v-bind:item_ver_comp="itemVerComp"
          :isMandated="isMandated"
        />
      </div>
      <div
        class="new-competition__wrap"
        v-if="this.allCompetition === null || !this.allCompetition.length"
      >
        <p class="new-competition__title">Нет предстоящих соревнований!</p>
        <img
          src="../../assets/img/empty_low.png"
          alt="application"
          class="new-competition__img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { actionTypes } from "@/store/modules/competition";
import Header from "../../components/Header.vue";
import ItemVerificationCompetition from "../../components/ItemVerificationCompetition.vue";
import BreadCrumps from '@/components/BreadCrumps.vue'
export default {
  components: { BreadCrumps, Header, ItemVerificationCompetition },
  name: "AppMandated",
  data() {
    return {
      isMandated: true,
    };
  },
  methods: {},
  computed: {
    ...mapState({
      isLoading: (state) => state.competition.isLoading,
      allCompetition: (state) => state.competition.allCompetition,
    }),
  },
  mounted() {
    this.$store
      .dispatch(actionTypes.allCompetition)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
