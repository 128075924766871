<template>
  <div class="create-application" v-if="this.aboutRegistrApp !== null">
    <Header message="Создание заявки" />
    <BreadCrumps/>
    <div class="create-application__container">
      <div class="create-application__information" >
      <div class="create-application__select-block">
        <div class="create-application__select-block-label">
          <p class="text-label">Мероприятие, на которое будет подана заявка:</p>
        </div>
        <div class="input-with-loader" v-if="this.isLoadingInfo">
          <label class="input-loader"
            ><lottie-animation
              ref="anim"
              :animationData="require('@/assets/lottie/input_loader.json')"
              :autoPlay="true"
              :loop="true"
              :speed="1"
          /></label>
<!--          <input type="text" class="input-text-index-2" />-->
        </div>
        <div
          class=""
          v-if="!this.isLoadingInfo && this.aboutRegistrApp !== null"
        >
          <input
            type="text"
            class="input-light-text"
            placeholder="Заполните организацию"
            name="comment"
            disabled
            v-model="aboutRegistrApp.title"
          />
        </div>
      </div>
      <div class="create-application__select-block">
        <div class="create-application__select-block-label">
          <p class="text-label">Организация подающая заявку:</p>
        </div>
        <div class="input-with-loader" v-if="this.isLoadingInfo">
          <label class="input-loader"
            ><lottie-animation
              ref="anim"
              :animationData="require('@/assets/lottie/input_loader.json')"
              :autoPlay="true"
              :loop="true"
              :speed="1"
          /></label>
<!--          <input type="text" class="input-text-index-2" />-->
        </div>
        <div
          class=""
          v-if="!this.isLoadingInfo && this.aboutRegistrApp !== null"
        >
          <input
            type="text"
            class="input-light-text"
            placeholder="Заполните организацию"
            name="comment"
            v-model="aboutRegistrApp.department"
          />
        </div>
      </div>
      <div class="create-application__region">
        <div class="create-application__region-wrap">
          <div class="create-application__select-block-label">
            <p class="text-label">Город*</p>
          </div>
          <div class="input-with-loader" v-if="this.isLoadingInfo">
            <label class="input-loader"
              ><lottie-animation
                ref="anim"
                :animationData="require('@/assets/lottie/input_loader.json')"
                :autoPlay="true"
                :loop="true"
                :speed="1"
            /></label>
            <input type="text" class="input-text-index-2" />
          </div>
          <div
            class=""
            v-if="!this.isLoadingInfo && this.aboutRegistrApp !== null"
          >
            <custom-select-universal
              @input="safeCity"
              :default="aboutRegistrApp.city !== null ? aboutRegistrApp.region : 'Выберите город'"
              :options="this.selectCity"
            />
          </div>
        </div>
        <div class="create-application__region-wrap">
          <div class="create-application__select-block-label">
            <p class="text-label">Область/регион*:</p>
          </div>
          <div class="input-with-loader" v-if="this.isLoadingInfo">
            <label class="input-loader"
              ><lottie-animation
                ref="anim"
                :animationData="require('@/assets/lottie/input_loader.json')"
                :autoPlay="true"
                :loop="true"
                :speed="1"
            /></label>
            <input type="text" class="input-text-index-2" />
          </div>
          <div
            class=""
            v-if="!this.isLoadingInfo && this.aboutRegistrApp !== null"
          >
            <custom-select-universal
              @input="safeRegion"
              :default="aboutRegistrApp.region !== null ? aboutRegistrApp.region : 'Выберите регион'"
              :options="this.selectRegion"
            />
          </div>
        </div>
      </div>
      </div>
      <div class="create-application__tab">
        <tab-menu-application
          ref="tabMenu"
          @showModalAddSportsmen="showModalAddSportsmen"
          @showModalAddGroup="showModalAddGroup"
          @showModalAddCoath="showModalAddCoath"
          @showModalAddJuj="showModalAddJuj"
          @submitApplication="submitApplication"
        />
      </div>
    </div>
    <modal-add-sportsmen
      v-if="showAddSportsmen"
      @addCoach="showAddCoach = true"
      @close="showAddSportsmen = false"
    />
    <modal-add-coach
      v-if="showAddCoach"
      @close="showAddCoach = false"
      @closeCoachOpenSport="(showAddCoach = false), (showAddSportsmen = true)"
    />

    <modal-add-juj
      v-if="showAddJuj"
      @close="showAddJuj = false"
      @closeCoachOpenSport="showAddJuj = false"
    />

    <modal-add-group
      v-if="showAddGroup"
      @close="showAddGroup = false"
      @addCoach="showAddCoach = true"
      @addSportsmen="showModalAddSportsmen"
    />
  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { actionTypesEntered } from "@/store/modules/enteredData";
import Header from "../components/Header.vue";
import ModalAddSportsmen from "../components/Modal/ModalAddSportsmen.vue";
import CustomSelectUniversal from "../components/customSelect/CustomSelectUniversal.vue";
import TabMenuApplication from "../components/CreateApplication/TabMenuApplication.vue";

import LottieAnimation from "lottie-web-vue";
import { mapState } from "vuex";
import ModalAddGroup from "../components/Modal/ModalAddGroup.vue";
import ModalAddJuj from "../components/Modal/ModalAddJuj.vue";
import ModalAddCoach from "../components/Modal/ModalAddCoach.vue";
import BreadCrumps from '@/components/BreadCrumps.vue'
export default {
  components: {
    BreadCrumps,
    Header,
    CustomSelectUniversal,
    LottieAnimation,
    ModalAddSportsmen,
    TabMenuApplication,
    ModalAddJuj,
    ModalAddGroup,
    ModalAddCoach,
  },
  name: "AppCreateApplication",
  data() {
    return {
      showAddSportsmen: false,
      showAddGroup: false,
      showAddCoach: false,
      showAddJuj: false,
      city: "",
      region: "",
      dataSubmit: {},
    };
  },
  methods: {
    showModalAddSportsmen() {
      this.showAddSportsmen = true;
    },
    showModalAddGroup() {
      this.showAddGroup = true;
    },
    safeCity(item) {
      this.city = item;
    },
    showModalAddCoath() {
      this.showAddCoach = true;
    },
    showModalAddJuj() {
      this.showAddJuj = true;
    },
    safeRegion(item) {
      this.region = item;
    },
    // createNewPeople() {
    //   console.log("Новый чел создан");
    //   this.$refs.tabMenu.createNewPeople();
    // },
    submitApplication() {
      if (this.city !== "") {
        this.aboutRegistrApp.city = this.city;
      }
      if (this.region !== "") {
        this.aboutRegistrApp.region = this.region;
      }
      this.dataSubmit = {
        idCompetition: this.$route.params.id,
        userId: this.infoUser.id,
        city: this.aboutRegistrApp.city,
        department: this.aboutRegistrApp.department,
        region: this.aboutRegistrApp.region,
      };
      // Окончательная регистрация заявки на соревнования
      this.$store
        .dispatch(actionTypes.submitApplication, this.dataSubmit)
        .then(() => {
          this.$router.push({ path: "/applications" });
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
  computed: {
    ...mapState({
      validationErrors: (state) => state.auth.validationErrors,
      isLoadingInfo: (state) => state.registration.isLoadingInfo,
      selectCity: (state) => state.selectCity,
      selectRegion: (state) => state.selectRegion,
      defaultRegion: (state) => state.defaultRegion,
      defaultCity: (state) => state.defaultCity,
      aboutRegistrApp: (state) => state.registration.aboutRegistrApp,
      infoUser: (state) => state.auth.infoUser,
    }),
  },
  mounted() {
    // getInfoAboutRegistrationApp
    this.$store
      .dispatch(actionTypes.getInfoAboutRegistrationApp, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    // получаем всех зарег судей категории
    this.$store
      .dispatch(actionTypesEntered.getJuj)
      .then(() => {})
      .catch((er) => {
        console.log(er, "Error");
      });
  },
};
</script>

<style></style>
