import competitionAPi from "@/api/competition.js";

const state = {
  isLoading: false, //ожидание запроса
  allCompetition: null,
  itemCompetition: null,
  validationErrors: null,
};

export const mutationTypes = {
  allCompetitionStart: "[competition] allCompetitionStart",
  allCompetitionSuccess: "[competition] allCompetitionSuccess",
  allCompetitionFailure: "[competition] allCompetitionFailure",

  idCompetitionStart: "[competition]  idCompetitionStart",
  idCompetitionSuccess: "[competition]  idCompetitionSuccess",
  idCompetitionFailure: "[competition]  idCompetitionFailure",
};
export const actionTypes = {
  idCompetition: "[competition] idCompetition",
  allCompetition: "[competition] allCompetition",
};

const mutations = {
  [mutationTypes.allCompetitionStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.allCompetitionSuccess](state, info) {
    state.isLoading = false;
    state.allCompetition = info.result;
  },
  [mutationTypes.allCompetitionFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  [mutationTypes.idCompetitionStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.idCompetitionSuccess](state, info) {
    state.isLoading = false;
    state.itemCompetition = info.result;
  },
  [mutationTypes.idCompetitionFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypes.allCompetition](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.allCompetitionStart);
      competitionAPi
        .allCompetition()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.allCompetitionSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.allCompetitionFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.allCompetitionFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypes.idCompetition](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.idCompetitionStart);
      competitionAPi
        .idCompetition(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.idCompetitionSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.idCompetitionFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.idCompetitionFailure, err);
          console.log("ошибка", err);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
