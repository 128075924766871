<template>
  <div class="routing-menu-in-page" v-if="token !== ''">
    <router-link to="/entered-data">
      <div class="routing-menu-in-page__block">
        <div class="routing-menu-in-page__img-wrap">
          <img src="../assets/svg/people.svg" class="routing-menu-in-page__img" alt="people">
        </div>

        <div class="routing-menu-in-page__text-wrap">
          <p class="routing-menu-in-page__text"> Внести данные</p>
        </div>
      </div>
    </router-link>
    <router-link to="/">
      <div class="routing-menu-in-page__block">
        <div class="routing-menu-in-page__img-wrap">
          <img src="../assets/svg/podium.svg" class="routing-menu-in-page__img" alt="people">
        </div>

        <div class="routing-menu-in-page__text-wrap">
          <p class="routing-menu-in-page__text">Выбрать соревнования</p>
        </div>
      </div>
    </router-link>
    <router-link to="/applications">
      <div class="routing-menu-in-page__block">
        <div class="routing-menu-in-page__img-wrap">
          <img src="../assets/svg/menu.svg" class="routing-menu-in-page__img" alt="people">
        </div>

        <div class="routing-menu-in-page__text-wrap">
          <p class="routing-menu-in-page__text">Мои заявки</p>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: "AppMiniRouting",
    data() {
      return {
      };
    },
    computed: {
      ...mapState({
        token: (state) => state.auth.token,

      }),
    },
  };
</script>

<style></style>
