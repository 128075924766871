<template>
  <div class="main__filter-app">
    <div class="filter">
      <div
        class="filter__block2"
        :class="{ filter__block: this.isActiveIndividualPerf }"
        @click="switchTabsClass('individ')"
      >
        <p
          class="filter__text2"
          :class="{ activeText: this.isActiveIndividualPerf }"
        >
          Индивидуальные
        </p>
      </div>
      <div
        class="filter__block2"
        :class="{ filter__block: this.isActiveGroupPerf }"
        @click="switchTabsClass('group')"
      >
        <p
          class="filter__text2"
          :class="{ activeText: this.isActiveGroupPerf }"
        >
          Групповые
        </p>
      </div>
      <div
        class="filter__block2"
        :class="{ filter__block: this.isActiveJuj }"
        @click="switchTabsClass('else')"
      >
        <p class="filter__text2" :class="{ activeText: this.isActiveJuj }">
          Судьи
        </p>
      </div>
    </div>
    <!-- ИНДИВИДУАЛЬНЫЕ ЗАЯВКИ -->
    <individual-application
      @showModalAddSportsmen="showModalAddSportsmen"
      ref="individulApp"
      v-if="this.isActiveIndividualPerf"
    />
    <!-- ГРУППОВЫЕ ЗАЯВКИ -->
    <group-application
      @showModalAddGroup="showModalAddGroup"
      v-if="this.isActiveGroupPerf"
    />
    <!-- СУДЕЙКИЕ ЗАЯВКИ -->

    <juj-application
      v-if="this.isActiveJuj"
      @showModalAddJuj="showModalAddJuj"
    />
    <div
      class="search__btn"
      v-if="this.$route.fullPath !== '/competition/3/edit-application'"
    >
<!--      <button-->
<!--        class="button"-->
<!--        @click="submitApplication"-->
<!--        v-if="isLoadingCreateApp"-->
<!--      >-->
<!--        <LottieAnimation-->
<!--          ref="anim"-->
<!--          :animationData="require('@/assets/lottie/loader.json')"-->
<!--          :autoPlay="true"-->
<!--          :loop="true"-->
<!--          class="loader-in-btn"-->
<!--          :speed="1"-->
<!--        />-->
<!--      </button>-->
      <button  v-if="aboutRegistrApp.status === 'DRAFT'"  class="button" @click="submitApplication">
        Отправить заявку
      </button>
    </div>
    <div class="search__btn" v-if="aboutRegistrApp.status !== 'DRAFT'" >
      <button class="button" @click="submitApplication">
        Сохранить изменения
      </button>
    </div>

  </div>
</template>

<script>
import { actionTypes } from "@/store/modules/registration";
import { mapState } from "vuex";
// import LottieAnimation from "lottie-web-vue";
import IndividualApplication from "./IndividualApplication.vue";
import GroupApplication from "./GroupApplication.vue";
import JujApplication from "./JujApplication.vue";

export default {
  name: "AppTabMenu",
  data() {
    return {
      isActiveIndividualPerf: true,
      isActiveGroupPerf: false,
      isActiveJuj: false,
    };
  },
  components: {
    IndividualApplication,
    GroupApplication,
    JujApplication,
    // LottieAnimation,
  },
  computed: {
    ...mapState({
      isLoadingCreateApp: (state) => state.registration.isLoadingCreateApp,
      aboutRegistrApp: (state) => state.registration.aboutRegistrApp,
    }),
  },
  methods: {
    switchTabsClass(tab) {
      if (tab === "individ") {
        this.isActiveIndividualPerf = true;
        this.isActiveGroupPerf = false;
        this.isActiveJuj = false;
      } else if (tab === "group") {
        this.isActiveIndividualPerf = false;
        this.isActiveGroupPerf = true;
        this.isActiveJuj = false;
      } else {
        this.isActiveIndividualPerf = false;
        this.isActiveGroupPerf = false;
        this.isActiveJuj = true;
      }
    },
    showModalAddSportsmen() {
      this.$emit("showModalAddSportsmen");
    },
    showModalAddGroup() {
      this.$emit("showModalAddGroup");
    },
    showModalAddCoath() {
      this.$emit("showModalAddCoath");
    },
    showModalAddJuj() {
      this.$emit("showModalAddJuj");
    },
    submitApplication() {
      this.$emit("submitApplication");
    },
  },
  mounted() {
    //получаем индивидуальные категории для этих соренований
    this.$store
      .dispatch(actionTypes.getIndividualCategories, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log(er, "Error");
      });
    // получаем групповые категории
    this.$store
      .dispatch(actionTypes.getGroupCategories, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log(er, "Error");
      });
  },
};
</script>

<style></style>
