var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-block__loader"},[(_vm.isLoadingPhoto)?_c('div',{staticClass:"lds-spinner"},[_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div'),_c('div')]):_vm._e(),_c('div',{staticClass:"preview-block"},[(_vm.isAddfoto === false && this.competitionItemInfo.urlImage !== '')?_c('img',{staticClass:"preview-block__img",attrs:{"src":`${_vm.dogImage}`,"alt":"foto"}}):_vm._e(),(
        _vm.isAddfoto === false &&
        _vm.isUpdatePhoto === true &&
        this.competitionItemInfo.urlImage == ''
      )?_c('img',{staticClass:"preview-block__img",attrs:{"src":`${_vm.dogImage}`,"alt":"foto"}}):_vm._e(),(
        _vm.isAddfoto === false &&
        _vm.isUpdatePhoto === false &&
        this.competitionItemInfo.urlImage === ''
      )?_c('div',{staticClass:"preview-block__empty-poster"},[_c('img',{attrs:{"src":require("../assets/svg/logoGray.svg"),"alt":""}})]):_vm._e()]),(_vm.isAddfoto === true)?_c('div',[_c('div',{staticClass:"client-block__img",attrs:{"id":"foto"}},_vm._l((this.newPhoto),function(file,index){return _c('div',{key:index,staticClass:"photo-item__column"},[_c('div',{staticClass:"preview-image"},[_c('div',{staticClass:"preview-remove",attrs:{"data-name":file.file.name},on:{"click":function($event){return _vm.removeHandler(index, file.file.name)}}},[_c('img',{attrs:{"src":require("../assets/svg/cross.svg"),"alt":"cross"}})]),_c('div',{staticClass:"preview-block"},[_c('img',{attrs:{"src":file.src,"alt":"foto"}})]),_c('div',{staticClass:"preview-upload"},[(_vm.isAddfoto === true)?_c('button',{staticClass:"preview-upload__btn",attrs:{"type":"submit"},on:{"click":function($event){return _vm.putImage()}}},[_vm._v(" Загрузить ")]):_vm._e()])])])}),0)]):_vm._e(),(_vm.validationErrors)?_c('ValidationErrors',{attrs:{"validation-errors":_vm.validationErrors}}):_vm._e(),(_vm.isAddfoto === false)?_c('div',{staticClass:"example-2"},[_c('div',{staticClass:"form-group"},[_c('form',{ref:"myForm"},[_c('input',{ref:"file",staticClass:"input-file",attrs:{"type":"file","id":"file","accept":"image/jpeg"},on:{"change":_vm.handleFileUpload}}),_vm._m(0)])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"btn btn-tertiary-new js-labelFileNew",attrs:{"for":"file"}},[_c('img',{staticClass:"btn-img",attrs:{"src":require("../assets/svg/download.svg"),"alt":"download"}}),_c('span',{staticClass:"js-fileName"},[_vm._v("Добавить постер")])])
}]

export { render, staticRenderFns }