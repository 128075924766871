import mandatedAPi from "@/api/mandated.js";
// import { setItem, getItem } from "@/helpers/persistanceStorage";

const state = {
  isLoadingInfoMandated: false, //ожидание запроса
  isLoadingAllSportsmen: false,
  validationErrors: null, //ошибки
  isUserRegistration: false,
  allInfoMandate: [],

  allSportsmens: null,
  allJudges: [],
  allCoaches: [],
  allGroups: null,
};

export const mutationTypes = {
  getAllInfoMandatedStart: "[mandated] getAllInfoMandatedStart",
  getAllInfoMandatedSuccess: "[mandated] getAllInfoMandatedSuccess",
  getAllInfoMandatedFailure: "[mandated] getAllInfoMandatedFailure",

  getAllSportsmenIndCategStart: "[mandated] getAllSportsmenIndCategStart",
  getAllSportsmenIndCategSuccess: "[mandated] getAllSportsmenIndCategSuccess",
  getAllSportsmenIndCategFailure: "[mandated] getAllSportsmenIndCategFailure",

  getAllGroupCategStart: "[mandated] getAllGroupCategStart",
  getAllGroupCategSuccess: "[mandated] getAllGroupCategSuccess",
  getAllGroupCategFailure: "[mandated] getAllGroupCategFailure",

  sortitionStart: "[mandated]  sortitionStart",
  sortitionSuccess: "[mandated]  sortitionSuccess",
  sortitionFailure: "[mandated]  sortitionFailure",
};
export const actionTypesMandated = {
  getAllInfoMandated: "[mandated] getAllInfoMandated",
  getAllSportsmenIndCateg: "[mandated] getAllSportsmenIndCateg",
  getAllGroupCateg: "[mandated] getAllGroupCateg",
  sortition: "[mandated]  sortition",
};

const mutations = {
  [mutationTypes.getAllInfoMandatedStart](state) {
    state.validationErrors = null;
    state.isLoadingInfoMandated = true;
  },
  [mutationTypes.getAllInfoMandatedSuccess](state, payload) {
    state.isLoadingInfoMandated = false;
    state.allInfoMandate = payload.result;
  },
  [mutationTypes.getAllInfoMandatedFailure](state, payload) {
    state.isLoadingInfoMandated = false;
    state.validationErrors = payload;
  },
  [mutationTypes.getAllSportsmenIndCategStart](state) {
    state.validationErrors = null;
    state.isLoadingAllSportsmen = true;
    state.allSportsmens = null;
    state.allGroups = null;
  },
  [mutationTypes.getAllSportsmenIndCategSuccess](state, payload) {
    state.isLoadingAllSportsmen = false;
    state.allSportsmens = payload.result;
  },
  [mutationTypes.getAllSportsmenIndCategFailure](state, payload) {
    state.isLoadingAllSportsmen = false;
    state.validationErrors = payload;
  },

  [mutationTypes.getAllGroupCategStart](state) {
    state.validationErrors = null;
    state.isLoadingAllSportsmen = true;
    state.allSportsmens = null;
    state.allGroups = null;
  },
  [mutationTypes.getAllGroupCategSuccess](state, payload) {
    state.isLoadingAllSportsmen = false;
    state.allGroups = payload.result;
  },
  [mutationTypes.getAllGroupCategFailure](state, payload) {
    state.isLoadingAllSportsmen = false;
    state.validationErrors = payload;
  },
  [mutationTypes.sortitionStart](state) {
    state.validationErrors = null;
    state.isLoadingSort = true;
  },
  [mutationTypes.sortitionSuccess](state, statusDraw) {
    state.isLoadingSort = false;
    state.allInfoMandate.isDraw = statusDraw;
  },
  [mutationTypes.sortitionFailure](state, payload) {
    state.isLoadingSort = false;
    state.validationErrors = payload;
  },
  //
};
const actions = {
  [actionTypesMandated.getAllInfoMandated](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllInfoMandatedStart);
      mandatedAPi
        .getAllInfoMandated(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getAllInfoMandatedSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getAllInfoMandatedFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getAllInfoMandatedFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypesMandated.getAllSportsmenIndCateg](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllSportsmenIndCategStart);
      mandatedAPi
        .getAllSportsmenIndCateg(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getAllSportsmenIndCategSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getAllSportsmenIndCategFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getAllInfoMandatedFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypesMandated.getAllGroupCateg](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getAllGroupCategStart);
      mandatedAPi
        .getAllGroupCateg(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getAllGroupCategSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getAllGroupCategFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getAllGroupCategFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypesMandated.sortition](context, idCompetition) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.sortitionStart);
      mandatedAPi
        .sortition(idCompetition)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.sortitionSuccess, true);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.sortitionFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.sortitionFailure, err);
          console.log("ошибка", err);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
