<template>
  <div class="home">
    <Header message="Главная" />
    <AppMiniRouting/>
    <BreadCrumps/>

    <competitions-blocks />
  </div>
</template>

<script>
import CompetitionsBlocks from "../components/CompetitionsBlocks.vue";
import Header from "../components/Header.vue";
import BreadCrumps from '@/components/BreadCrumps.vue'
import AppMiniRouting from '@/components/MiniRouting.vue'
export default {
  components: { AppMiniRouting, BreadCrumps, Header, CompetitionsBlocks },
  name: "Home",
};
</script>
