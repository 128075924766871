<template>
  <div>
    <button @click="toggleMenu" class="burger-menu" v-if="!is_expanded && windowWidth <= 1024"><img src="../assets/svg/burger.svg"></button>
    <aside :class="`${is_expanded ? 'is-expanded' : ''}`">
      <div v-if="is_expanded && windowWidth <= 1024" @click="toggleMenu" class="close-btn"><img src="../assets/svg/close.svg"></div>
      <router-link to="/"  @click="toggleMenu">
        <div class="logo">
          <img src="../assets/svg/LogoRG-hor.svg" alt="Logo" />
        </div>
      </router-link>
      <div class="menu">
        <router-link to="/" class="button-new"  >
          <span class="text" @click="toggleMenu">Главная</span>
        </router-link>
        <router-link to="/entered-data" class="button-new" >
          <span class="text" @click="toggleMenu">Мои данные</span>
        </router-link>
        <router-link
            to="/new-competition"
            class="button-new"
            v-if="infoUser.role === 'ADMIN'"
        >
          <span class="text" @click="toggleMenu">Мои соревнования</span>
        </router-link>
        <router-link to="/applications" class="button-new"  >
          <span class="text" @click="toggleMenu">Подача заявок</span>
        </router-link>
        <!--      <router-link to="/profile" class="button-new">-->
        <!--        <span class="text">Профиль</span>-->
        <!--      </router-link>-->
        <router-link
            to="/mandated"
            class="button-new"

            v-if="infoUser.role === 'ADMIN'"
        >
          <span class="text" @click="toggleMenu">Мандатная</span>
        </router-link>
        <router-link to="/login" class="button-new" v-if="isMobile" >
          <span class="text" @click="exit">Выход</span>
        </router-link>
      </div>
      <div class="flex"></div>
    </aside>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { actionTypes } from '@/store/modules/auth'

  export default {
    name: "AppSidebar",
    data() {
      return {
        is_expanded: true,
        windowWidth: window.innerWidth, // Инициализируем начальное значение
      };
    },
    computed: {
      ...mapState({
        infoUser: (state) => state.auth.infoUser,
      }),
      isMobile() {
        return this.windowWidth <= 1024; // Логика определения мобильного режима
      },
      shouldMenuBeExpanded() {
        return this.is_expanded && this.isMobile;
      },
    },
    methods: {
      toggleMenu() {
        if(this.isMobile) {
          this.is_expanded = !this.is_expanded;
          localStorage.setItem("is_expanded", String(this.is_expanded));
        }
      },
      handleResize() {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 1024 && this.is_expanded) {
          this.toggleMenu(); // Автоматически закрывать меню на мобильных устройствах
        }
      },
      exit() {
        this.$store
            .dispatch(actionTypes.exitLogin)
            .then(() => {})
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
    },
    mounted() {
      this.handleResize(); // Вызовите сразу после монтирования, чтобы корректно установить начальное состояние
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
  };
</script>

<style lang="scss" scoped>
.burger-menu, .close-btn {
  display: none;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 101;
  cursor: pointer;
  border-radius: 50px;
  background-color: white;
  padding: 10px;
  width: 40px;
  height: 40px;
  -webkit-box-shadow: 2px -2px 20px 2px rgba(0,0,0,0.25);
  -moz-box-shadow: 2px -2px 20px 2px rgba(0,0,0,0.25);
  box-shadow: 2px -2px 20px 2px rgba(0,0,0,0.25), 0px 13px 20px rgba(0, 0, 0, 0.05), 14px 0px 30px 18px rgba(0, 0, 0, 0.03);
  img {
    width: 20px;
    height: 20px;
  }

}
aside {
  display: flex;
  flex-direction: column;
  //background-color: #f9f6f0;
  width: 312px;
  min-height: 100vh;
  //Анимацию добавить
  transition: opacity 0.6s ease;
  //position: fixed;
  z-index: 100;
  .flex {
    flex: 1 1 0%;
  }
  .logo {
    margin: 29px 91px 81px 61px;
    img {
      width: 160px;
    }
  }
  .menu {
    .button-new {
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: 0.2s ease-in-out;
      padding: 17px 0px 17px 80px;
      .text {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #9b9b9b;
      }
      ///* Стили для активного пункта меню */
      &.router-link-exact-active {
        background: #EEEEE9;

        .material-icons,
        .text {
          color: var(--menu-text);
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
        }
      }
      &:hover .text {
        color: var(--menu-text-hover);
      }
      &.router-link-exact-active .text {
        color: var(--menu-text-active);
      }
    }
  }
  &.is-expanded {
    //width: 312px;
    width: var(--sidebar-width);
    background-color: #ffffff;
    -webkit-box-shadow: 2px -2px 30px 2px rgba(0,0,0,0.25);
    -moz-box-shadow: 2px -2px 30px 2px rgba(0,0,0,0.25);
    box-shadow: 2px -2px 30px 2px rgba(0,0,0,0.25), 0px 13px 30px rgba(0, 0, 0, 0.05), 14px 0px 60px 18px rgba(0, 0, 0, 0.03);
    border-radius: 0px 15px 15px 0px;
  }
}



@media (max-width: 1024px) {
  aside {
    width: 0;
    overflow: hidden;
  }

  aside.is-expanded {
    width: 100%;
    position: fixed;
  }

  .burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close-btn {
    display: block; /* Show close button when sidebar is expanded */
  }
}
</style>
