import axios from "@/api/axios";

// const authorizationAxios = axios.create({
//   baseURL: "http://109.111.185.150:9021",
// });

const login = (credentials) => {
  console.log(credentials);
  return axios.post(`/login`, credentials);
};
const registration = (credentials) => {
  return axios.post(`/registration`, credentials, {
    useCredentails: true,
  });
};
const forgotPassword= (email) => {
  return axios.post(`/user/forgot/password?email=${email}`);
};
export default {
  login,
  registration,
  forgotPassword
};
