<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form @submit.prevent="safeInfoJuj" id="#modalModificationJuj">
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Добавление судьи</p>
              </div>
              <div class="modal-scroll-max">
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row-3">
                  <div class="input-wrap__label">
                    <p class="text-label">Фамилия:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    placeholder="Фамилия"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.surname.$error,
                    }"
                    name="surname"
                    :value="item_juj.surname"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putData.surname.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-row-3">
                  <div class="input-wrap__label">
                    <p class="text-label">Имя:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.name.$error,
                    }"
                    placeholder="Имя"
                    name="name"
                    :value="item_juj.name"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putData.name.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-row-3">
                  <div class="input-wrap__label">
                    <p class="text-label">Отчество:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putData.patronymic.$error,
                    }"
                    placeholder="Отчество"
                    name="patronymic"
                    :value="item_juj.patronymic"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putData.patronymic.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>
              <div class="input-wrap__column">
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="text-label">Судейская категория:</p>
                  </div>
                  <custom-select-rank
                    @input="safeRank"
                    :default="item_juj.category"
                    :options="this.selectJujCateg"
                  />
                  <!-- <custom-select @input="safeRank" :options="this.selectRank" /> -->
                </div>
              </div>
              <div class="input-wrap__column">
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="text-label">Телефон:</p>
                  </div>
                  <input
                      type="tel"
                      class="input-light-text"
                      :class="{'error-border-input': isSubmitted && $v.putData.phone.$error}"
                      placeholder="+7 (***) ***-****"
                      name="phone"
                      v-mask="'+7##########'"
                      v-model.lazy="item_juj.phone"
                      @input="handleInput"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putData.phone.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Область/регион:</p>
                  </div>
                  <custom-select-universal
                    @input="safeRegion"
                    :default="item_juj.region"
                    :options="this.selectRegion"
                  />
                </div>
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="text-label">Город:</p>
                  </div>
                  <custom-select-universal
                    @input="safeCity"
                    :default="item_juj.city"
                    :options="this.selectCity"
                  />
                </div>
              </div>
            </div>

            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="button">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button class="button-transponent" @click="$emit('close')">
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { actionTypesEntered } from "@/store/modules/enteredData";
import CustomSelectUniversal from "../customSelect/CustomSelectUniversal.vue";
import { mapState } from "vuex";
import CustomSelectRank from "../customSelect/CustomSelectRank.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "AddModalModificationJuj",
  data() {
    return {
      name: "",
      surname: "",
      patronymic: "",
      phone: "",
      rank: "",
      city: "",
      region: "",
      putData: [],
      selectRegion: ["Новосибирская область"],
      selectJujCateg: [
        { id: 1, name: "1 категория" },
        { id: 2, name: "2 категория" },
        { id: 3, name: "3 категория" },
        { id: 4, name: "ССВК" },
        { id: 5, name: "юный судья" },
        { id: 6, name: "б/к" },
      ],
      selectCity: ["Новосибирск","Краснообск", "Бердск", "Академгородок"],
      defaultRegion: "Выберите регион",
      defaultCity: "Выберите город",
      defaultCateg: "Выберите категорию",
      isSubmitted: false,
    };
  },
  validations: {
    putData: {
      name: {
        required,
      },
      surname: {
        required,
      },
      patronymic: {
        required,
      },
      phone: {
        required,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
    },
  },
  props: {
    item_juj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    CustomSelectUniversal,
    CustomSelectRank,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      selectRank: (state) => state.registration.selectRank,
    }),
  },
  methods: {
    safeCity(item) {
      console.log(item, "city");
      this.city = item;
    },
    safeRegion(item) {
      console.log(item, "region");
      this.region = item;
    },
    handleInput(event) {
      // Логика обработки ввода, например, удаление нежелательных символов или проверка формата номера
      const value = event.target.value.replace(/[^\d+]/g, '');
      this.item_juj.phone = value; // Обновление значения с применением нужной логики
    },
    safeRank(item) {
      console.log(item, "rank");
      this.rank = item;
    },
    findCity() {
      this.selectCity.map((item) => {
        if (this.item_juj.city === item) {
          return (this.city = item);
        }
      });
    },
    findRegion() {
      this.selectRegion.map((item) => {
        if (this.item_juj.region === item) {
          return (this.region = item);
        }
      });
    },
    findRank() {
      this.selectJujCateg.map((item) => {
        if (this.item_juj.category === item.name) {
          return (this.rank = item);
        }
      });
    },
    safeInfoJuj() {
      const formData = new FormData(
        document.getElementById("#modalModificationJuj")
      );
      this.putData = [];
      for (let [name, value] of formData) {
        this.putData = { ...this.putData, [name]: value };
        console.log([name], value);
      }
      this.putData = {
        ...this.putData,
        region: this.region,
        city: this.city,
        category: this.rank.name,
        id: this.item_juj.id,
      };
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return console.log("Данные не заполенены");
      } else {
        //  Изменение Судьи
        this.$store
          .dispatch(actionTypesEntered.editJuj, this.putData)
          .then(() => {
            this.$emit("close");
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
  },
  mounted() {
    this.findCity();
    this.findRegion();
    this.findRank();
  },
};
</script>

<style></style>
