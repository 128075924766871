<template>
  <div class="perfomance__container">
    <form action="" class="form">
      <fieldset
        class="fieldset"
        :class="{
          'error-fieldset': isSubmitted && $v.individualCategory.$error,
        }"
      >
        <legend class="legend">1. Выберите категорию:</legend>
        <div class="form__row">
          <div class="form__group-row">
            <input
              type="radio"
              name="options"
              id="category1"
              class="radio-tag"
              value="A"
              v-model="individualCategory"
            />
            <label for="category1"> Категория A</label>
          </div>
          <div class="form__group-row">
            <input
              type="radio"
              name="options"
              id="category2"
              class="radio-tag"
              value="B"
              v-model="individualCategory"
            />
            <label for="category2"> Категория B</label>
          </div>
          <div class="form__group-row">
            <input
              type="radio"
              id="category3"
              name="options"
              class="radio-tag"
              value="C"
              v-model="individualCategory"
            />
            <label for="category3"> Категория C</label>
          </div>
        </div>
      </fieldset>
      <fieldset
          class="fieldset"
          :class="{
          'error-fieldset': isSubmitted && $v.gender.$error,
        }"
      >
        <legend class="legend">2. Выберите пол :</legend>
        <div class="form__row">
          <div class="form__group-row">
            <input
                type="radio"
                name="genderM"
                id="genderM"
                class="radio-tag"
                value="GIRL"
                v-model="gender"
            />
            <label for="genderM">Ж</label>
          </div>
          <div class="form__group-row">
            <input
                type="radio"
                name="genderG"
                id="genderG"
                class="radio-tag"
                value="MAN"
                v-model="gender"
            />
            <label for="genderG">М</label>
          </div>
        </div>
      </fieldset>
      <fieldset
        class="fieldset"
        :class="{
          'error-fieldset':
            (isSubmitted && $v.fromAge.$error) ||
            (isSubmitted && $v.beforeAge.$error),
        }"
      >
        <legend class="legend">3. Выберите год рождения:</legend>
        <div class="perfomance__row">
          <p class="perfomance__text">от</p>

          <date-picker
            v-model="fromAge"
            input-class="perfomance__input-date"
            value-type="format"
            format="YYYY"
            maxlength="4"
            max
            type="year"
          ></date-picker>

          <p class="perfomance__text">до</p>
          <date-picker
            v-model="beforeAge"
            value-type="format"
            format="YYYY"
            maxlength="4"
            input-class="perfomance__input-date"
            type="year"
          ></date-picker>
          <p class="perfomance__text">Пример: (от 2010 до 2012)</p>
        </div>
      </fieldset>
      <fieldset
        class="fieldset"
        :class="{
          'error-fieldset': isSubmitted && $v.performedTypes.$error,
        }"
      >
        <legend class="legend">4. Количество выполняемых видов:</legend>
        <div class="form__row">
          <div
            class=""
            v-for="performed in performedTypesLocal"
            :key="performed.id"
          >
            <div class="form__group-row">
              <input
                :id="performed.id"
                type="checkbox"
                :value="performed.id"
                v-model="performedTypes"
              />
              <label :for="performed.id"> {{ performed.name }}</label>
            </div>
          </div>
        </div>
        <legend class="legend">
          Выбрано: {{ performedTypes.length }}
          <span v-if="performedTypes.length === 1">вид</span>
          <span
            v-else-if="performedTypes.length > 1 && performedTypes.length < 5"
            >вида</span
          >
          <span
            v-else-if="performedTypes.length < 1 || performedTypes.length > 4"
            >видов</span
          >
        </legend>
      </fieldset>
      <fieldset
        class="fieldset"
        :class="{
          'error-fieldset': isSubmitted && $v.rank.$error,
        }"
      >
        <legend class="legend">5. Выступает по разряду:</legend>
        <template>
          <div class="form__row">
            <div class="" v-for="item in ranks" :key="item.id">
              <div class="form__group-row">
                <input
                  type="radio"
                  :id="item.id + 'range'"
                  :value="item.id"
                  :name="item.name"
                  v-model="rank"
                />
                <label :for="item.id + 'range'"> {{ item.name }}</label>
              </div>
            </div>
          </div>
        </template>
      </fieldset>
      <div class="creation__btn">
        <div class="creation__btn-wrap">
          <button type="button" class="button-green" @click="addCategory">
            Добавить
          </button>
        </div>
        <button
          type="button"
          class="button-transponent"
          @click="switchTabsClass"
        >
          Пропустить
        </button>
      </div>
    </form>
    <div class="perfomance-category">
      <div class="perfomance-category__title">
        <p class="perfomance-category__title-text">собранные категории</p>
      </div>
      <div class="divTable">
        <div class="divTableHeading">
          <div class="divTableRow">
            <div class="divTableHead">Категория</div>
            <div class="divTableHead">Год рождения</div>
            <div class="divTableHead">Выполняемые виды</div>
            <div class="divTableHead">Выступают по разряду</div>
          </div>
        </div>
        <div
          class="divTableBody"
          v-if="competitionIndividualCateg && competitionIndividualCateg.length"
        >
          <item-collect-competition
            v-for="itemCateg in competitionIndividualCateg"
            :key="itemCateg.id"
            v-bind:item_categ="itemCateg"
            @deleteCateg="deleteCateg"
          />
        </div>
        <div class="" v-else>
          <empty-block style="max-width: 100%" />
        </div>
      </div>
    </div>
    <div class="creation__btn">
      <button class="button" @click="switchTabsClass">Далее</button>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { mapState } from "vuex";
import { actionTypes } from "@/store/modules/registrationComp";
import ItemCollectCompetition from "./ItemCollectCompetition.vue";
import EmptyBlock from "./EmptyBlock.vue";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AppIndividualPerfomance",
  data() {
    return {
      individualCategory: "",
      fromAge: "",
      beforeAge: "",
      performedTypes: [],
      rank: "",
      categ: {},
      idCateg: {},
      isSubmitted: false,
      gender: '',
    };
  },
  props: {
    id: {
      type: [String, Number],
      default() {
        return {};
      },
    },
  },
  validations: {
    individualCategory: {
      required,
    },
    gender: {
      required,
    },

    fromAge: {
      required,
    },
    beforeAge: {
      required,
    },
    rank: {
      required,
    },
    performedTypes: {
      required,
    },
  },
  components: {
    DatePicker,
    ItemCollectCompetition,
    EmptyBlock,
  },
  computed: {
    ...mapState({
      validationErrors: (state) => state.registrationComp.validationErrors,
      isLoading: (state) => state.registrationComp.isLoading,
      performedTypesLocal: (state) => state.registrationComp.performedTypes,
      ranks: (state) => state.registrationComp.ranks,
      competitionIndividualCateg: (state) =>
        state.registrationComp.competitionIndividualCateg,
    }),
  },
  methods: {
    addCategory() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return console.log("Данные не заполенены");
      } else {
        this.categ = {
          individualCategory: this.individualCategory,
          fromAge: this.fromAge,
          beforeAge: this.beforeAge,
          gender: this.gender,
          performedTypes: this.performedTypes,
          rank: this.rank,
          competitionId: this.id,
        };
        this.$store
          .dispatch(actionTypes.competitionsRegSecondStep, this.categ)
          .then(() => {
            this.individualCategory = "";
            this.gender = '',
            this.fromAge = "";
            this.isSubmitted = false;
            this.beforeAge = "";
            this.performedTypes = [];
            this.rank = "";
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
    deleteCateg(id) {
      this.idCateg = {
        id: id,
        competitionId: this.id,
      };
      this.$store
        .dispatch(actionTypes.competitionsDeleteSecondStep, this.idCateg)
        .then(() => {})
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    switchTabsClass() {
      this.$emit("switchTabsClass", "group");
    },
  },
  mounted() {
    this.$store
      .dispatch(actionTypes.individualCategory)
      .then(() => {
        this.$store
          .dispatch(actionTypes.competitionAllIndividual, this.id)
          .then(() => {})
          .catch((er) => {
            console.log("Ошибка", er);
          });
      })
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
