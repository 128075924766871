<template>
  <tr>
    <td class="tb__checkbox">
      {{ this.item_sportsmen.name }} {{ this.item_sportsmen.surname }}
    </td>
    <td class="tb__name">{{ this.newData[0] }}</td>
    <td class="tb__name">{{ this.item_sportsmen.rank }}</td>
    <td class="tb__name">{{ this.item_sportsmen.coachNames }}</td>
  </tr>
</template>

<script>
export default {
  name: "AppItemSportsmenInTableGroup",
  props: {
    item_sportsmen: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    newData() {
      return this.item_sportsmen.dateOfBirth.split("-");
    },
  },
};
</script>

<style></style>
