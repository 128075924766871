<template>
  <div class="empty-block">
    <div class="empty-block__info">
      <img
        class="empty-block__img"
        src="../assets/svg/empty-wallet-tick.svg"
        alt="empty"
      />
    </div>
    <p class="index-text">Нет заполненных данных</p>
  </div>
</template>

<script>
export default {
  name: "AppEmptyBlock",
};
</script>
