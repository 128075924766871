<template>
  <div class="detail-competition-category__item-categ">
    <img src="../../assets/svg/user-dark.svg" alt="user" />
    <div class="detail-competition-category__item-categ-wrap">
      <p class="text-description">
        {{ item_ind_categ.individualCategory }} &nbsp;
      </p>
      <div class="detail-competition-category__item-categ-age">
        <p class="text-description" v-if="item_ind_categ.beforeAge !== null">
          {{ item_ind_categ.beforeAge }}-
        </p>
        <p class="text-description" v-if="item_ind_categ.fromAge !== null">
          {{ item_ind_categ.fromAge }} г.р.&nbsp;
        </p>
        <p class="text-description" v-else>г.р.&nbsp;</p>
      </div>
      <p class="text-descriptiont">{{ item_ind_categ.performedTypes }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppItemIndividualCateg",
  props: {
    item_ind_categ: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style>
</style>