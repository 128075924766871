import axios from "axios";
import { getItem } from "@/helpers/persistanceStorage";
import router from "@/router";
// axios.defaults.baseURL = "http://localhost:9041/api/1";

// axios.defaults.baseURL = "http://109.111.185.150:9041/api/1";
axios.defaults.baseURL = "https://reg-gym.ru/api/1";


// axios.defaults.baseURL = "http://80.64.169.167:9041/api/1";
//

axios.interceptors.response.use(
  (response) => {
    console.log(response, "response inter");
    if (response.status === 401) {
      alert("You are not authorized");
    }
    return response;
  },
  (error) => {
    console.log(error, "error resp", error.response?.status);
    if (error.response?.status === 401) {
      localStorage.removeItem("accessTokenStoreApplication");
      localStorage.removeItem("infoUserApplication");
      router.push({ name: "Login" });
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);
axios.interceptors.request.use(
  (config) => {
    const token = getItem("accessTokenStoreApplication");
    const authorizationToken = token ? `${token}` : "";
    if (authorizationToken === "") {
      console.log(config);
    } else {
      config.headers.Authorization = authorizationToken;
    }
    console.log(config, "request");
    return config;
  },
  function (error) {
    console.log("ПЕРЕКЛЮЧИСЬ НА НОВЫЙ base url");
    return Promise.reject(error);
  }
);

export default axios;
