<template>
  <div class="new-competition">
    <Header message="Мои соревнования" />
    <div class="new-competition__container">
      <div class="tab-competition">
        <div
          class="tab-competition__block"
          :class="[isActiveBlockNowComp ? 'tab-active' : 'tab-noactive']"
          @click="switchTabsClass('now')"
        >
          <p class="tab-competition__text">Соревнования</p>
        </div>
        <div
          class="tab-competition__block"
          :class="[isActiveBlockDraftComp ? 'tab-active' : 'tab-noactive']"
          @click="switchTabsClass('draft')"
        >
          <p class="tab-competition__text">Черновики</p>
        </div>
      </div>
      <div
        class="new-competition__wrap"
        v-if="this.userCompetition === null || !this.userCompetition.length"
      >
        <p class="new-competition__title">Нет созданных соревнований!</p>
        <img
          src="../assets/img/empty_low.png"
          alt="application"
          class="new-competition__img"
        />
      </div>
      <div class="competition" v-if="isActiveBlockNowComp">
        <item-competition
          v-for="itemComp in this.finishCompetition"
          :key="itemComp.id"
          v-bind:item_comp="itemComp"
        />
      </div>
      <div class="competition" v-if="isActiveBlockDraftComp">
        <item-competition
          v-for="itemComp in this.draftCompetition"
          :key="itemComp.id"
          v-bind:item_comp="itemComp"
        />
      </div>

      <div class="tab-competition__button">
        <button class="button-green" @click="createCompetition">
          Создать соревнования
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import { actionTypes } from "@/store/modules/registrationComp";
import { mapState } from "vuex";
import ItemCompetition from "../components/ItemCompetition.vue";
export default {
  name: "AppNewCompetition",
  data() {
    return {
      isActiveBlockNowComp: true,
      isActiveBlockDraftComp: false,
    };
  },
  props: {
    item_data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      userCompetition: (state) => state.registrationComp.userCompetition,
      draftCompetition: (state) => state.registrationComp.draftCompetition,
      finishCompetition: (state) => state.registrationComp.finishCompetition,
    }),
  },
  components: { Header, ItemCompetition },
  methods: {
    switchTabsClass(tab) {
      if (tab === "now") {
        this.isActiveBlockNowComp = true;
        this.isActiveBlockDraftComp = false;
      } else if (tab === "draft") {
        this.isActiveBlockNowComp = false;
        this.isActiveBlockDraftComp = true;
      }
    },
    createCompetition() {
      this.$store
        .dispatch(actionTypes.competitionsReg)
        .then((response) => {
          this.$router.push({
            name: "Creation",
            params: { id: response.result, item: null },
          });
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
  },
  mounted() {
    //получение всех соревнований
    this.$store
      .dispatch(actionTypes.allUserCompetition)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
