<template>
  <tr>
    <td class="edit__btn">
      <img
        class="icon__edit"
        src="../../assets/svg/pain.svg"
        alt="Edit"
        @click="editJuj()"
      />
      <img
        class="icon__delete"
        src="../../assets/svg/trash.svg"
        alt="Delete"
        @click="deleteJuj()"
      />
    </td>

    <td class="tb__checkbox">
      {{ item_juj.surname }} {{ item_juj.name }}
      {{ item_juj.patronymic }}
    </td>
    <td class="tb__name">
      {{ item_juj.category }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "AppItemTableSportsmen",
  data() {
    return {
      checkedP: false,
    };
  },
  props: {
    item_juj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheck");
    },
    deleteJuj() {
      this.$emit("deleteJuj", this.item_juj);
    },
    editJuj() {
      this.$emit("editJuj", this.item_juj);
    },
  },
};
</script>

<style></style>
