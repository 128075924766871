<template>
  <table class="table">
    <thead>
      <tr>
        <th class="empty_th"></th>
        <th class="th__name">Название</th>
        <th class="th__name" style="width: 20%">Тренеры</th>
      </tr>
    </thead>
    <tbody>
      <ItemTableGroup
        v-for="(group, i) in this.allGroups"
        :key="i"
        :item_group="group"
        @deleteGroup="deleteGroup"
        @editGroup="editGroup"
      />
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
// import { actionTypesEntered } from "@/store/modules/enteredData";

import ItemTableGroup from "./ItemTableGroup.vue";
export default {
  components: { ItemTableGroup },
  name: "AppTableGroup",
  data() {
    return {};
  },
  methods: {
    submitData() {
      this.$emit("checkCoach", this.checkedCoach);
    },
    addCoach() {
      this.$emit("addCoach");
    },
    editGroup(item) {
      this.$emit("editGroup", item);
    },
    deleteGroup(item) {
      console.log(item);
      // удаление групп
      this.$emit("deleteGroup", item, "GROUP");
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.enteredData.isLoading,
      coaches: (state) => state.enteredData.allCoaches,
      allGroups: (state) => state.enteredData.allGroups,
    }),
  },
};
</script>

<style></style>
