import withoutAutAPi from "@/api/withoutAut.js";

const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null,
  allCompetitionsNotAuth: null,
  itemCompetitionNotAuth:null,
};

export const mutationTypes = {
  allCompetitionsStart: "[withoutAuthorization] allCompetitionsStart",
  allCompetitionsSuccess: "[withoutAuthorization] allCompetitionsSuccess",
  allCompetitionsFailure: "[withoutAuthorization] allCompetitionsFailure",

  idCompetitionStart: "[withoutAuthorization] idCompetitionStart",
  idCompetitionSuccess: "[withoutAuthorization] idCompetitionSuccess",
  idCompetitionFailure: "[withoutAuthorization] idCompetitionFailure",
};
export const actionTypesWithoutAuth = {
  allCompetitions: "[withoutAuthorization] allCompetitions",
  idCompetition: "[withoutAuthorization] idCompetition"
};

const mutations = {
  [mutationTypes.allCompetitionsStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.allCompetitionsSuccess](state, info) {
    state.isLoading = false;
    state.allCompetitionsNotAuth = info.result;
  },
  [mutationTypes.allCompetitionsFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

  [mutationTypes.idCompetitionStart](state) {
    state.isLoading = true;
  },
  [mutationTypes.idCompetitionSuccess](state, info) {
    state.isLoading = false;
    state.itemCompetitionNotAuth = info.result;
  },
  [mutationTypes.idCompetitionFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },

};
const actions = {
  [actionTypesWithoutAuth.allCompetitions](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.allCompetitionsStart);
      withoutAutAPi
          .allCompetitions()
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.allCompetitionsSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.allCompetitionsFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.allCompetitionsFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  [actionTypesWithoutAuth.idCompetition](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.idCompetitionStart);
      withoutAutAPi
          .idCompetition(id)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.idCompetitionSuccess, response.data);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.idCompetitionFailure, response.data);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.idCompetitionFailure, err);
            console.log("ошибка", err);
          });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
