<template>
  <div style="width: 100%">
    <div class="accordion">
      <div class="accordion-header-people">
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">
            {{ selected.surname }} {{ selected.name }}
          </p>
          <p class="selected-text">ФИО</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">
            {{ this.newData[0] }}
          </p>
          <p class="selected-text">Год рождения</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">{{ selected.rank }}</p>
          <p class="selected-text">Разряд</p>
        </div>
        <!-- <div class="accordion-header-people__block" v-if="selected.coachNames">
          <p class="accordion-header-people__text">{{ selected.coachNames }}</p>
          <p class="selected-text">Тренер</p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSelectWithCheckPeople",
  data() {
    return {
      isOpen: false,

      selectWithCateg: {},
    };
  },
  computed: {
    newData() {
      return this.selected.dateOfBirth.split("-");
    },
    selected() {
      return this.checkSelect;
    },
  },
  props: {
    checkSelect: {
      type: Object,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    //  selectIndCateg: {
    //    type: Object,
    //    required: true,
    //  },
  },
  methods: {},
};
</script>

<style></style>
