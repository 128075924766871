import axios from "@/api/axios";

const allCompetition = () => {
  return axios.get(`/competition/get/all`);
};
const idCompetition = (idCompetition) => {
  return axios.get(`/get/competition/${idCompetition}`);
};
export default {
  allCompetition,
  idCompetition,
};
