<template>
  <tr>
    <td class="tb__name">
      {{ item_group.categ.name }}
    </td>
    <td class="tb__name">
      {{ item_group.group.name }}
    </td>

    <td class="tb__name">
      {{ item_group.group.coachGroup }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "AppItemTableAppGroup",
  data() {
    return {
      checkedP: false,
    };
  },
  props: {
    item_group: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style></style>
