<template>
  <div class="">
    <div class="accordion" :class="{ 'is-open': isOpen }">
      <div
        class="accordion-header"
        @click="toggleAccordion"
        v-if="selected === this.default"
      >
        {{ selected }}
      </div>
      <div class="accordion-header" @click="toggleAccordion" v-else>
        {{ selected.name }}
        <!-- <p class="selected-text">Заявленная категория</p> -->
      </div>
      <div class="accordion-body items" ref="body">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="safeSelect(option), toggleAccordion()"
        >
          {{ option.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    safeSelect(option) {
      this.selected = option;
      this.$emit("input", option);
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.animateOpen();
      } else {
        this.animateClose();
      }
    },
    animateOpen() {
      const body = this.$refs.body;
      const height = body.scrollHeight;
      body.style.height = "0";
      setTimeout(() => {
        body.style.height = `${height}px`;
      }, 5);
    },
    animateClose() {
      const body = this.$refs.body;
      body.style.height = `${body.scrollHeight}px`;
      setTimeout(() => {
        body.style.height = "0";
      }, 5);
    },
  },
};
</script>

<style></style>
