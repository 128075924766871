import axios from "@/api/axios";

//получения всех зарегистрированный тренеров
const getCoaches = () => {
  return axios.get(`/get/coaches`);
};
//получение зарегистрированных спортменов
const getSportsmens = () => {
  return axios.get(`/get/all-user/sportsmen`);
};
const getRank = () => {
  return axios.get(`/get/all/rank`);
};
//получение индивидуальных категорий
const getIndividualCategories = (competitionId) => {
  return axios.get(`/individual/${competitionId}`);
};
//получение спортсменов в соответсвии с категорией
const getIndividualSportsmens = (data) => {
  return axios.get(
    `/get/all_sportsmen/category_user/${data.categId}/${data.idCompetition}`
  );
};
//Получение категорий групп
const getGroupCategories = (competitionId) => {
  return axios.get(`/group/${competitionId}`);
};
//получение информации о всем при оформлении заявки
const getInfoAboutRegistrationApp = (competitionId) => {
  return axios.get(`/competition/application/${competitionId}`);
};
//добавление спорсмена в заявку
const addSportsmenApp = (data) => {
  return axios.post(`/application/registration_sportsmen`, data);
};
//удаление спортсмена из заявки
const deleteSportsmenApp = (data) => {
  return axios.post(`/application/delete_sportsmen`, data);
};
//добавление группы в заявку
const addGroupApp = (data) => {
  return axios.post(`/application/registration_group`, data);
};
//удаление группы из заявки
const deleteGroupApp = (data) => {
  return axios.post(`/application/delete_group`, data);
};
//добавление судьи в заявку
const addJujApp = (data) => {
  return axios.post(`/application/registration_judge`, data);
};
//удаление судьи из заявку
const deleteJujApp = (data) => {
  return axios.post(`/application/delete_judge`, data);
};
//отправка заявки
const submitApplication = (data) => {
  return axios.post(`/application/reg`, data);
};
//все заявки
const allApplications = () => {
  return axios.get(`/application_get_all`);
};

export default {
  getCoaches,
  getSportsmens,
  getIndividualCategories,
  getRank,
  getIndividualSportsmens,
  getInfoAboutRegistrationApp,
  addSportsmenApp,
  deleteSportsmenApp,
  getGroupCategories,
  addGroupApp,
  deleteGroupApp,
  addJujApp,
  deleteJujApp,
  submitApplication,
  allApplications,
};
