<template>
  <div class="create-start-list">
    <Header message="Создание стартового протокола" />
    <div class="create-start-list__container">
      <div class="create-start-list__select-block">
        <div class="create-start-list__select-block-label">
          <p class="text-label">
            Мероприятие на которое составляется стартовый:
          </p>
        </div>
        <div class="input-with-loader" v-if="this.isLoading">
          <label class="input-loader"
            ><lottie-animation
              ref="anim"
              :animationData="require('@/assets/lottie/input_loader.json')"
              :autoPlay="true"
              :loop="true"
              :speed="1"
          /></label>
          <input type="text" class="input-text-index-2" />
        </div>
        <input
          type="text"
          class="input-text-index-2"
          placeholder="Заполните название соревнований"
          name="comment"
          v-model="nameStartList"
        />

        <div class="create-start-list__row">
          <div class="create-start-list__calendar">
            <div class="create-start-list__select-block-label">
              <p class="text-label">Дата и время начала соревнований:</p>
            </div>
            <input
              type="datetime-local"
              name="meeting-time"
              min="2023-04-01T00:00"
              max="2025-06-01T00:00"
              class="input-text-index-2"
              placeholder="Время, место"
              v-model="startDateAndTime"
            />
          </div>
          <div class="create-start-list__time">
            <div class="create-start-list__select-block-label">
              <p class="text-label">Время на одно выступление:</p>
            </div>
            <div class="create-start-list__wrap-input">
              <input
                type="number"
                min="1"
                max="10"
                class="input-text-index-2"
                placeholder="Время"
                v-model="timeForPerformans"
              />

              <p class="create-start-list__text">мин.</p>
            </div>
          </div>
        </div>
        <div class="">
          <!-- <div class="create-start-list__title">
            <p class="title-mini">Создание стартового протокола</p>
          </div> -->
          <!-- <list-nomination :nameStartList="nameStartList" /> -->
          <new-list-nomination
            :nameStartList="nameStartList"
            @submitStartList="submitStartList"
          />
        </div>
        <div class="">
          <div class="create-start-list__title">
            <p class="title-mini">Создание перерывов</p>
          </div>
          <div class="create-start-list__select-block-label">
            <p class="text-label">Название перерыва:</p>
          </div>
          <div class="">
            <input
              type="text"
              class="input-text-index-2"
              placeholder="Заполните название перерыва"
              name="comment"
              v-model="nameBreak"
            />
          </div>
          <div class="create-start-list__row-spase-between">
            <div class="create-start-list__row">
              <div class="create-start-list__calendar">
                <div class="create-start-list__select-block-label">
                  <p class="text-label">Время начала перерыва:</p>
                </div>

                <input
                  type="time"
                  class="input-text-index-2"
                  min="07:00"
                  max="22:00"
                  placeholder="Время, место"
                  v-model="startBreakTime"
                />
              </div>
              <div class="create-start-list__time">
                <div class="create-start-list__select-block-label">
                  <p class="text-label">Время конца перерыва:</p>
                </div>
                <div class="create-start-list__wrap-input">
                  <input
                    type="time"
                    class="input-text-index-2"
                    min="07:00"
                    max="22:00"
                    placeholder="Время"
                    v-model="endBreakTime"
                  />
                </div>
              </div>
            </div>
            <div class="create-start-list__wrap-btn">
              <button
                class="button"
                @click="createListBreak"
                :disabled="isDisabled"
              >
                <img
                  src="../assets/svg/plus.svg"
                  alt="plus"
                  class="button__img"
                  v-bind:class="{
                    'display-none': isDisabled,
                  }"
                />
                Добавить перерыв
              </button>
            </div>
          </div>
          <div class="list-break" v-if="this.listBreak.length !== 0">
            <div class="create-start-list__title">
              <p class="title-mini">Список перерывов</p>
            </div>
            <div class="list-break__wrap" v-if="this.listBreak.length === 0">
              <p class="list-break__title">
                *На данный момент никаких перерывов не добавлено
              </p>
              <img
                src="../assets/img/empty_low.png"
                alt="application"
                class="list-break__img"
              />
            </div>
            <div v-else>
              <div
                class="list-break__wrap-item"
                v-for="(item, index) in this.listBreak"
                :key="index"
              >
                <div class="item-break">
                  <div class="item-break__wrap-text">
                    <p class="item-break__text">{{ index + 1 }}.</p>
                    <p class="item-break__text">
                      {{ item.startBreakTime }}-{{ item.endBreakTime }}
                      {{ item.nameBreak }}
                    </p>
                  </div>
                  <div class="" @click="deleteBreack(index)">
                    <img src="../assets/svg/trash.svg" alt="trash" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="create-start-list__btn">
        <button
          class="button"
          @click="submitAllInfoStartList"
          :disabled="
            (this.nameStartList === '' && this.nameStartList) ||
            (this.startDateAndTime === '' && this.startDateAndTime) ||
            (this.timeForPerformans === '' && this.timeForPerformans) ||
            this.resultStartList.length === 0
          "
        >
          Создать стартовый протокол
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";

import { actionTypesStartList } from "@/store/modules/createStartList";
import LottieAnimation from "lottie-web-vue";
import { mapState } from "vuex";

import NewListNomination from "../components/DragGable/NewListNomination.vue";
import axios from "axios";
// import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Header,
    LottieAnimation,

    NewListNomination,
  },
  name: "AppCreateStartList",
  data() {
    return {
      // name: "",
      nameBreak: "",
      startBreakTime: "",
      endBreakTime: "",
      startDateAndTime: "",
      timeForPerformans: "",
      listBreak: [],
      nameStartList: "",
      resultStartList: [],
      unificationStartList: [],
      endData: {},
      isGroup: false,
    };
  },
  // validations: {
  //   nameStartList: {
  //     required,
  //   },
  //   timeForPerformans: {
  //     required,
  //   },
  //   startDateAndTime: {
  //     required,
  //   },
  //   resultStartList: {
  //     required,
  //   },
  // },
  computed: {
    ...mapState({
      validationErrors: (state) => state.createStartList.validationErrors,
      isLoading: (state) => state.createStartList.isLoading,
    }),
    isDisabled() {
      return (
        this.endBreakTime === "" ||
        this.startBreakTime === "" ||
        this.nameBreak === ""
      );
    },
  },
  methods: {
    createListBreak() {
      const objectBreak = {
        nameBreak: this.nameBreak,
        startBreakTime: this.startBreakTime,
        endBreakTime: this.endBreakTime,
      };
      console.log(objectBreak);
      this.listBreak.push(objectBreak);
      this.nameBreak = "";
      this.startBreakTime = "";
      this.endBreakTime = "";
    },
    deleteBreack(index) {
      this.listBreak.splice(index, 1);
    },
    submitStartList(startList) {
      this.unificationStartList = startList.reduce((r, cur) => {
        const key = cur["idCategory"];
        if (cur["type"] === "Группы") {
          this.isGroup = true;
        } else {
          this.isGroup = false;
        }
        (r[key] = r[key] || []).push({
          category: cur.category,
          order: cur.order,
          union: cur.union,
          isGroup: this.isGroup,
        });

        return r;
      }, {});
      this.resultStartList = [];
      for (var key in this.unificationStartList) {
        this.resultStartList.push({
          idCategory: Number(key),
          isGroup: this.unificationStartList[key][0].isGroup,
          performanceType: this.unificationStartList[key],
        });
      }

      // );
    },
    submitAllInfoStartList() {
      console.log({
        listBreak: this.listBreak,
        startDateAndTime: this.startDateAndTime,
        resultStartList: this.resultStartList,
        timeForPerformans: this.timeForPerformans,
        nameStartList: this.nameStartList,
      });
      this.endData = {
        listBreak: this.listBreak,
        startDateAndTime: this.startDateAndTime,
        resultStartList: this.resultStartList,
        timeForPerformans: this.timeForPerformans,
        nameStartList: this.nameStartList,
      };
      axios
        .post(`/create/protocol`, this.endData, {
          responseType: "blob",
        })
        .then((response) => {
          console.log(response);
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          var fileName = `Протокол.xlsx`;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
      // this.$store
      //   .dispatch(actionTypesStartList.postAllInfoAboutStartList, this.endData)
      //   .then(() => {})
      //   .catch((er) => {
      //     console.log("Ошибка", er);
      //   });
    },
  },
  mounted() {
    // Получение информации о номинациях
    this.$store
      .dispatch(actionTypesStartList.getNominationsList, this.$route.params.id)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
    //
    this.$store
      .dispatch(
        actionTypesStartList.getNominationGroupList,
        this.$route.params.id
      )
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
