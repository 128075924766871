<template>
  <div class="divTableRow1">
    <div class="divTableCell" v-if="this.item_categ.individualCategory">
      {{ this.item_categ.individualCategory }}
    </div>
    <!-- groupCategory -->
    <div class="divTableCell" v-else>{{ this.item_categ.groupCategory }}</div>
    <div class="divTableCell" v-if="item_categ.beforeAge !== null">
      {{ this.item_categ.fromAge }} - {{ item_categ.beforeAge }}
    </div>
    <div class="divTableCell" v-else>
      {{ this.item_categ.fromAge }}
    </div>

    <div class="divTableCell">{{ this.item_categ.performedTypes }}</div>
    <div class="divTableCell">{{ this.item_categ.rank }}</div>
    <div class="divTableRowCross" @click="deleteCateg">
      <img src="../assets/svg/trash.svg" alt="trash" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemCollectCompetition",
  props: {
    item_categ: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    deleteCateg() {
      this.$emit("deleteCateg", this.item_categ.id);
    },
  },
};
</script>

<style></style>
