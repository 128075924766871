<template>
  <tr>
    <td class="tb__name">
      {{ item_sportsmen.categ.name }}
    </td>
    <td class="tb__name">
      {{ item_sportsmen.sportsmen.surname }} {{ item_sportsmen.sportsmen.name }}
    </td>
    <td class="tb__name">
      {{ this.newData[0] }}
    </td>
    <td class="tb__name">
      {{ item_sportsmen.sportsmen.rank }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "AppItemTableAppSportsmen",
  data() {
    return {
      checkedP: false,
    };
  },
  props: {
    item_sportsmen: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    checkedCoach: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(val, "val");
        this.checkedP = val;
      },
    },
    newData() {
      return this.item_sportsmen.sportsmen.dateOfBirth.split("-");
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheck");
    },
    deleteSportsmen() {
      this.$emit("deleteSportsmen", this.item_sportsmen);
    },
    editSportsmen() {
      this.$emit("editSportsmen", this.item_sportsmen);
    },
  },
};
</script>

<style></style>
