import authAPi from "@/api/auth.js";
import { setItem, getItem } from "@/helpers/persistanceStorage";
import axios from "axios";
import router from "@/router";

const state = {
  isLoading: false, //ожидание запроса
  token: localStorage.getItem("accessTokenStoreApplication") || "",
  isAuthenticated: false, //авторизирован ли пользователь
  validationErrorsLogin: null, //ошибки
  validationErrors: null,
  validationErrorsRegistration: null, //ошибки
  isUserRegistration: false,
  messageSuccess: null,
  infoUser: getItem("infoUserApplication") || null,
};

export const mutationTypes = {
  loginStart: "[auth] loginStart",
  loginSuccess: "[auth] loginSuccess",
  loginFailure: "[auth] loginFailure",
  registrationStart: "[auth] registrationStart",
  registrationSuccess: "[auth] registrationSuccess",
  registrationFailure: "[auth] registrationFailure",
  forgotPasswordStart: "[auth] forgotPasswordStart",
  forgotPasswordSuccess: "[auth] forgotPasswordSuccess",
  forgotPasswordFailure: "[auth] forgotPasswordFailure",


  exitLoginSuccess: "[auth] exit success",
};
export const actionTypes = {
  login: "[auth] login",
  registration: "[auth] registration",
  forgotPassword: "[auth] fogotPassword",
  exitLogin: "[auth] exit",
};

const mutations = {
  [mutationTypes.loginStart](state) {
    state.isAuthenticated = false;
    state.validationErrorsLogin = null;
    state.isLoading = true;
  },
  [mutationTypes.loginSuccess](state, response) {
    console.log(response);
    state.isAuthenticated = true;
    state.token = response.token;
    state.infoUser = response.userInfo;
    state.isLoading = false;
  },
  [mutationTypes.loginFailure](state, payload) {
    state.isAuthenticated = false;
    state.validationErrorsLogin = payload;
    state.isLoading = false;
  },
  [mutationTypes.registrationStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
    state.isUserRegistration = false;
  },
  [mutationTypes.registrationSuccess](state) {
    state.isLoading = false;
    state.validationErrorsRegistration = null
    state.isUserRegistration = true;
  },
  [mutationTypes.registrationFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsRegistration = payload;
    state.isUserRegistration = false;
  },
  [mutationTypes.exitLoginSuccess](state) {
    state.token = "";
    state.isAuthenticated = false;
    state.infoUser = null;
  },
  // forgotPassword
  [mutationTypes.forgotPasswordStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
    state.messageSuccess = null;
  },
  [mutationTypes.forgotPasswordSuccess](state, message) {
    state.isLoading = false;
    state.messageSuccess = message;
  },
  [mutationTypes.forgotPasswordFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
};
const actions = {
  [actionTypes.exitLogin](context) {
    context.commit(mutationTypes.exitLoginSuccess);
    localStorage.removeItem("accessTokenStoreApplication");
    localStorage.removeItem("infoUserApplication");
    delete axios.defaults.headers.common["Authorization"];
    router.push({ name: "Login" });
  },
  [actionTypes.login](context, credentials) {
    console.log(credentials);
    return new Promise((resolve) => {
      context.commit(mutationTypes.loginStart);
      authAPi
        .login(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            window.localStorage.setItem(
              "accessTokenStoreApplication",
              response.data.result.token
            );
            setItem("accessTokenStoreApplication", response.data.result.token);

            context.commit(mutationTypes.loginSuccess, response.data.result);
            setItem("infoUserApplication", response.data.result.userInfo);

            resolve(response.data);
          } else {
            context.commit(mutationTypes.loginFailure, response.data.message);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.loginFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // forgotPassword
  [actionTypes.forgotPassword](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.forgotPasswordStart);
      authAPi
          .forgotPassword(credentials)
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              context.commit(mutationTypes.forgotPasswordSuccess, response.data.result);
              resolve(response.data);
            } else {
              context.commit(mutationTypes.forgotPasswordFailure, response.data.message);
            }
          })
          .catch((err) => {
            context.commit(mutationTypes.forgotPasswordFailure, err);
            console.log("ошибка", err);
          });
    });
  },
  [actionTypes.registration](context, credentials) {
    console.log(credentials);
    return new Promise((resolve) => {
      context.commit(mutationTypes.registrationStart);
      authAPi
        .registration(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.registrationSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.registrationFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.registrationFailure, err);
          console.log("ошибка", err);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
