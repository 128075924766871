var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal__cross",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("../../assets/svg/cross.svg"),"alt":"cross"}})])]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.safeInfoJuj.apply(null, arguments)}}},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-body__title"},[_c('p',{staticClass:"modal__title"},[_vm._v("Добавление судьи")])]),(_vm.validationErrorsJuj)?_c('ValidationErrors',{attrs:{"validation-errors":_vm.validationErrorsJuj.message}}):_vm._e(),_c('div',{staticClass:"modal-scroll-max"},[(_vm.isSubmitted && this.$v.$invalid)?_c('div',{staticClass:"message-about-empty empty"},[_c('p',{staticClass:"message-about-empty__text"},[_vm._v("Заполните все данные!")])]):_vm._e(),_c('div',{staticClass:"input-wrap__row"},[_c('div',{staticClass:"input-wrap__label-with-input-row-3"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"label-text"},[_vm._v("Фамилия:")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.surname),expression:"surname"}],staticClass:"input-light-text",class:{
                    'error-border-input': _vm.isSubmitted && _vm.$v.surname.$error,
                  },attrs:{"type":"text","placeholder":"Фамилия","name":"surname"},domProps:{"value":(_vm.surname)},on:{"input":function($event){if($event.target.composing)return;_vm.surname=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.surname.$error)?_c('img',{staticClass:"wrapValidationInput__error",attrs:{"src":require("@/assets/svg/Error.svg"),"alt":"error"}}):_vm._e()]),_c('div',{staticClass:"input-wrap__label-with-input-row-3"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"label-text"},[_vm._v("Имя:")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"input-light-text",class:{
                    'error-border-input': _vm.isSubmitted && _vm.$v.name.$error,
                  },attrs:{"type":"text","placeholder":"Имя","name":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.name.$error)?_c('img',{staticClass:"wrapValidationInput__error",attrs:{"src":require("@/assets/svg/Error.svg"),"alt":"error"}}):_vm._e()]),_c('div',{staticClass:"input-wrap__label-with-input-row-3"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"label-text"},[_vm._v("Отчество:")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.patronymic),expression:"patronymic"}],staticClass:"input-light-text",class:{
                    'error-border-input': _vm.isSubmitted && _vm.$v.patronymic.$error,
                  },attrs:{"type":"text","placeholder":"Отчество","name":"patronymic"},domProps:{"value":(_vm.patronymic)},on:{"input":function($event){if($event.target.composing)return;_vm.patronymic=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.patronymic.$error)?_c('img',{staticClass:"wrapValidationInput__error",attrs:{"src":require("@/assets/svg/Error.svg"),"alt":"error"}}):_vm._e()])]),_c('div',{staticClass:"input-wrap__column"},[_c('div',{staticClass:"input-wrap__label-with-input-column"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"label-text"},[_vm._v("Судейская категория:")])]),_c('custom-select-rank',{class:{
                    'error-border-input': _vm.isSubmitted && _vm.$v.rank.$error,
                  },attrs:{"default":this.defaultCateg,"options":this.selectJujCateg},on:{"input":_vm.safeRank}})],1)]),_c('div',{staticClass:"input-wrap__column"},[(_vm.isSubmitted && _vm.$v.phone.$error)?_c('div',{staticClass:"validation"},[(!_vm.$v.phone.minLength || !_vm.$v.phone.maxLength || _vm.$v.phone.$error)?_c('span',{staticClass:"validation__text"},[_vm._v("*Номер телефона должен состоять из 12 символов")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input-wrap__label-with-input-column"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"label-text"},[_vm._v("Телефон:")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"},{name:"mask",rawName:"v-mask",value:('+7##########'),expression:"'+7##########'"}],staticClass:"input-light-text",class:{'error-border-input': _vm.isSubmitted && _vm.$v.phone.$error},attrs:{"type":"tel","placeholder":"+7 (***)***-****","name":"phone"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value}}}),(_vm.isSubmitted && _vm.$v.phone.$error)?_c('img',{staticClass:"wrapValidationInput__error",attrs:{"src":require("@/assets/svg/Error.svg"),"alt":"error"}}):_vm._e()])]),_c('div',{staticClass:"input-wrap__row"},[_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"label-text"},[_vm._v("Область/регион:")])]),_c('custom-select-universal',{class:{
                    'error-border-input': _vm.isSubmitted && _vm.$v.region.$error,
                  },attrs:{"default":this.defaultRegion,"options":this.selectRegion},on:{"input":_vm.safeRegion}})],1),_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"label-text"},[_vm._v("Город:")])]),_c('custom-select-universal',{class:{
                    'error-border-input': _vm.isSubmitted && _vm.$v.city.$error,
                  },attrs:{"default":this.defaultCity,"options":this.selectCity},on:{"input":_vm.safeCity}})],1)])])],1),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"modal-footer__button"},[_c('button',{staticClass:"button"},[_vm._v("Сохранить")])]),_c('div',{staticClass:"modal-footer__button"},[_c('button',{staticClass:"button-transponent",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Отменить ")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }