<template>
  <div class="profile">
    <Header message="Профиль" />
    <form
      class="profile__info"
      id="profile-info"
      name="profile-info"
      v-if="this.infoProfile !== null"
    >
      <div class="message-about-empty empty" v-if="!infoUser.profileIsEmpty">
        <p class="message-about-empty__text">
          Для создания заявки необходимо заполнить все данные!
        </p>
      </div>
      <div class="message-about-empty confirmed" v-else>
        <p class="message-about-empty__text">
          Ваши данные подтверждены, Спасибо!
        </p>
      </div>
      <!-- <div class="profile__info"> -->
      <div class="profile__about-name">
        <div class="block-profile__img">
          <img src="../assets/svg/User.svg" alt="" />
        </div>
        <div class="block-profile__info">
          <fieldset class="block-profile__wrap-input">
            <div class="wrapValidationInput">
              <p class="label-text-profile" for="lastname">Фамилия</p>
              <input
                type="text"
                class="input-text-profile"
                placeholder="Фамилия"
                name="surname"
                :class="{
                  'error-border-input':
                    infoProfile.surname === null || infoProfile.surname === '',
                }"
                v-model="infoProfile.surname"
              />
              <img
                src="~@/assets/svg/Error.svg"
                alt="error"
                v-if="
                  infoProfile.surname === null || infoProfile.surname === ''
                "
                class="wrapValidationInput__error"
              />
            </div>
            <div class="wrapValidationInput">
              <p class="label-text-profile" for="firstname">Имя</p>
              <input
                type="text"
                class="input-text-profile"
                placeholder="Имя"
                name="name"
                :class="{
                  'error-border-input':
                    infoProfile.name === null || infoProfile.name === '',
                }"
                v-model="infoProfile.name"
              />

              <img
                src="~@/assets/svg/Error.svg"
                alt="error"
                v-if="infoProfile.name === null || infoProfile.name === ''"
                class="wrapValidationInput__error"
              />
            </div>
          </fieldset>
        </div>
      </div>
      <div class="wrapValidationInput">
        <p class="label-text-profile" for="club">Отчество</p>
        <input
          type="text"
          class="input-text-profile"
          placeholder="Отчество"
          name="middleName"
          :class="{
            'error-border-input':
              infoProfile.middleName === null || infoProfile.middleName === '',
          }"
          v-model="infoProfile.middleName"
        />
        <img
          src="~@/assets/svg/Error.svg"
          alt="error"
          v-if="
            infoProfile.middleName === null || infoProfile.middleName === ''
          "
          class="wrapValidationInput__error"
        />
      </div>
      <div class="profile__about-data">
        <fieldset class="block-profile__wrap-input-2 margin-right-20">
          <p class="label-text-profile" for="email">Почта</p>
          <input
            type="text"
            class="input-text-profile"
            placeholder="Почта"
            name="email"
            disabled
            v-model="infoProfile.email"
          />
          <div class="wrapValidationInput">
            <p class="label-text-profile" for="country">Страна</p>
            <custom-select-universal
              @input="safeCountry"
              :default="
                infoProfile.country !== null && infoProfile.country !== ''
                  ? infoProfile.country
                  : this.defaultCountry
              "
              class="input-text-profile-margin"
              :options="this.selectCountry"
              :class="{
                'error-border-input':
                  (infoProfile.country === null && this.country === '') ||
                  (infoProfile.country === '' && this.country === ''),
              }"
            />
            <!-- <img
              src="~@/assets/svg/Error.svg"
              alt="error"
              v-if="
                (infoProfile.country === null && this.country === '') ||
                (infoProfile.country === '' && this.country === '')
              "
              class="wrapValidationInput__error-select"
            /> -->
          </div>
        </fieldset>

        <fieldset class="block-profile__wrap-input-2">
          <div class="wrapValidationInput">
            <p class="label-text-profile" for="phone">Телефон</p>
            <input
              type="text"
              class="input-text-profile"
              placeholder="Телефон"
              name="phone"
              :class="{
                'error-border-input':
                  infoProfile.phone === null || infoProfile.phone === '',
              }"
              v-model="infoProfile.phone"
            />
            <img
              src="~@/assets/svg/Error.svg"
              alt="error"
              v-if="infoProfile.phone === null || infoProfile.phone === ''"
              class="wrapValidationInput__error"
            />
          </div>
          <div class="wrapValidationInput">
            <p class="label-text-profile" for="region">Область/регион*</p>
            <custom-select-universal
              @input="safeRegion"
              :default="
                infoProfile.region !== null && infoProfile.region !== ''
                  ? infoProfile.region
                  : this.defaultRegion
              "
              class="input-text-profile-margin"
              :options="this.selectRegion"
              :class="{
                'error-border-input':
                  (infoProfile.region === null && this.region === '') ||
                  (infoProfile.region === '' && this.region === ''),
              }"
            />

            <!-- <img
              src="~@/assets/svg/Error.svg"
              alt="error"
              v-if="
                (infoProfile.region === null && this.region === '') ||
                (infoProfile.region === '' && this.region === '')
              "
              class="wrapValidationInput__error"
            /> -->
          </div>
        </fieldset>
      </div>
      <div class="profile__about-club">
        <div class="wrapValidationInput">
          <p class="label-text-profile" for="city">Город</p>
          <custom-select-universal
            @input="safeCity"
            :default="
              infoProfile.city !== null && infoProfile.city !== ''
                ? infoProfile.city
                : this.defaultCity
            "
            class="input-text-profile-margin"
            :options="this.selectCity"
            :class="{
              'error-border-input':
                (infoProfile.city === null && this.city === '') ||
                (infoProfile.city === '' && this.city === ''),
            }"
          />

          <!-- <img
            src="~@/assets/svg/Error.svg"
            alt="error"
            v-if="
              (infoProfile.city === null && this.city === '') ||
              (infoProfile.city === '' && this.city === '')
            "
            class="wrapValidationInput__error-select"
          /> -->
        </div>
        <div class="wrapValidationInput">
          <p class="label-text-profile" for="club">ДСО / ведомство / школа</p>
          <input
            type="text"
            class="input-text-profile"
            placeholder="Спортивный клуб"
            name="department"
            :class="{
              'error-border-input':
                infoProfile.department === null ||
                infoProfile.department === '',
            }"
            v-model="infoProfile.department"
          />
          <img
            src="~@/assets/svg/Error.svg"
            alt="error"
            v-if="
              infoProfile.department === null || infoProfile.department === ''
            "
            class="wrapValidationInput__error"
          />
        </div>
      </div>
      <div class="profile__btn">
        <div class="block-profile__button">
          <button class="button" @click="putInfoAboutProfile">Сохранить</button>
        </div>
        <div class="block-profile__button">
          <button class="button">Отменить</button>
        </div>
      </div>
    </form>
    <!-- </div> -->
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import { actionTypes } from "@/store/modules/information";
import { mapState } from "vuex";
import CustomSelectUniversal from "../components/customSelect/CustomSelectUniversal.vue";
export default {
  name: "AppProfile",
  data() {
    return {
      newInfoAboutUser: [],
      city: "",
      region: "",
      country: "",
      // defaultRegion: "Выберите регион",
      // defaultCity: "Выберите город",
    };
  },
  components: { Header, CustomSelectUniversal },
  computed: {
    ...mapState({
      validationErrors: (state) => state.auth.validationErrors,
      isLoading: (state) => state.auth.isLoading,
      infoProfile: (state) => state.info.infoProfile,
      infoUser: (state) => state.auth.infoUser,
      selectCity: (state) => state.selectCity,
      selectCountry: (state) => state.selectCountry,
      selectRegion: (state) => state.selectRegion,
      defaultRegion: (state) => state.defaultRegion,
      defaultCity: (state) => state.defaultCity,
      defaultCountry: (state) => state.defaultCountry,
    }),
  },

  methods: {
    putInfoAboutProfile(e) {
      e.preventDefault();

      const formElement = document.getElementById("profile-info");

      const formData = new FormData(formElement);
      this.newInfoAboutUser = [];

      for (let [name, value] of formData) {
        this.newInfoAboutUser = { ...this.newInfoAboutUser, [name]: value };
        console.log(name, value);
      }
      this.newInfoAboutUser = {
        ...this.newInfoAboutUser,
        region: this.region,
        city: this.city,
        country: this.country,
      };

      this.$store
        .dispatch(actionTypes.editInfoProfile, this.newInfoAboutUser)
        .then(() => {
          this.newInfoAboutUser === {};
        })
        .catch((er) => {
          console.log("Ошибка", er);
        });
    },
    safeCity(item) {
      console.log(item, "city");
      this.city = item;
    },
    safeRegion(item) {
      console.log(item, "region");
      this.region = item;
    },
    safeCountry(item) {
      this.country = item;
    },
    //найти выбранные город
    findCity() {
      this.selectCity.map((item) => {
        if (this.infoProfile.city === item) {
          return (this.city = item);
        }
      });
    },
    findRegion() {
      this.selectRegion.map((item) => {
        if (this.infoProfile.region === item) {
          return (this.region = item);
        }
      });
    },
    findCountry() {
      this.selectCountry.map((item) => {
        if (this.infoProfile.country === item) {
          return (this.country = item);
        }
      });
    },
  },
  mounted() {
    this.$store
      .dispatch(actionTypes.profileInfo)
      .then(() => {
        this.findCity();
        this.findRegion();
        this.findCountry();
      })
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
