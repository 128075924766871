var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"perfomance__container"},[_c('form',{staticClass:"form",attrs:{"action":""}},[_c('fieldset',{staticClass:"fieldset",class:{
        'error-fieldset': _vm.isSubmitted && _vm.$v.groupCategory.$error,
      }},[_c('legend',{staticClass:"legend"},[_vm._v("1. Выберите категорию:")]),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__group-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupCategory),expression:"groupCategory"}],staticClass:"radio-tag",attrs:{"type":"radio","id":"category-group1","name":"options","value":"A"},domProps:{"checked":_vm._q(_vm.groupCategory,"A")},on:{"change":function($event){_vm.groupCategory="A"}}}),_c('label',{attrs:{"for":"category-group1"}},[_vm._v(" Категория A")])]),_c('div',{staticClass:"form__group-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupCategory),expression:"groupCategory"}],staticClass:"radio-tag",attrs:{"type":"radio","id":"category-group2","name":"options","value":"B"},domProps:{"checked":_vm._q(_vm.groupCategory,"B")},on:{"change":function($event){_vm.groupCategory="B"}}}),_c('label',{attrs:{"for":"category-group2"}},[_vm._v(" Категория B")])])])]),_c('fieldset',{staticClass:"fieldset",class:{
        'error-fieldset':
          (_vm.isSubmitted && _vm.$v.fromAge.$error) ||
          (_vm.isSubmitted && _vm.$v.beforeAge.$error),
      }},[_c('legend',{staticClass:"legend"},[_vm._v("2. Выберите год рождения:")]),_c('div',{staticClass:"perfomance__row"},[_c('p',{staticClass:"perfomance__text"},[_vm._v("от")]),_c('date-picker',{attrs:{"input-class":"perfomance__input-date","value-type":"format","format":"YYYY","maxlength":"4","type":"year"},model:{value:(_vm.fromAge),callback:function ($$v) {_vm.fromAge=$$v},expression:"fromAge"}}),_c('p',{staticClass:"perfomance__text"},[_vm._v("до")]),_c('date-picker',{attrs:{"value-type":"format","format":"YYYY","maxlength":"4","input-class":"perfomance__input-date","type":"year"},model:{value:(_vm.beforeAge),callback:function ($$v) {_vm.beforeAge=$$v},expression:"beforeAge"}}),_c('p',{staticClass:"perfomance__text"},[_vm._v("Пример: (от 2010 до 2012)")])],1)]),_c('fieldset',{staticClass:"fieldset",class:{
        'error-fieldset': _vm.isSubmitted && _vm.$v.performedTypes.$error,
      }},[_c('legend',{staticClass:"legend"},[_vm._v("3. Количество выполняемых видов:")]),_c('div',{staticClass:"form__row"},_vm._l((_vm.performedTypesLocal),function(performed){return _c('div',{key:performed.id},[_c('div',{staticClass:"form__group-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.performedTypes),expression:"performedTypes"}],attrs:{"id":performed.id + 'group-performed',"type":"checkbox"},domProps:{"value":performed.id,"checked":Array.isArray(_vm.performedTypes)?_vm._i(_vm.performedTypes,performed.id)>-1:(_vm.performedTypes)},on:{"change":function($event){var $$a=_vm.performedTypes,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=performed.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.performedTypes=$$a.concat([$$v]))}else{$$i>-1&&(_vm.performedTypes=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.performedTypes=$$c}}}}),_c('label',{attrs:{"for":performed.id + 'group-performed'}},[_vm._v(" "+_vm._s(performed.name))])])])}),0),_c('legend',{staticClass:"legend"},[_vm._v(" Выбрано: "+_vm._s(_vm.performedTypes.length)+" "),(_vm.performedTypes.length === 1)?_c('span',[_vm._v("вид")]):(_vm.performedTypes.length > 1 && _vm.performedTypes.length < 5)?_c('span',[_vm._v("вида")]):(_vm.performedTypes.length < 1 || _vm.performedTypes.length > 4)?_c('span',[_vm._v("видов")]):_vm._e()])]),_c('fieldset',{staticClass:"fieldset",class:{
        'error-fieldset': _vm.isSubmitted && _vm.$v.rank.$error,
      }},[_c('legend',{staticClass:"legend"},[_vm._v("4. Выступает по разряду:")]),[_c('div',{staticClass:"form__row"},_vm._l((_vm.ranks),function(item){return _c('div',{key:item.id},[_c('div',{staticClass:"form__group-row"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rank),expression:"rank"}],attrs:{"type":"radio","id":item.id + 'group-rank',"name":item.name},domProps:{"value":item.id,"checked":_vm._q(_vm.rank,item.id)},on:{"change":function($event){_vm.rank=item.id}}}),_c('label',{attrs:{"for":item.id + 'group-rank'}},[_vm._v(" "+_vm._s(item.name))])])])}),0)]],2),_c('div',{staticClass:"creation__btn"},[_c('div',{staticClass:"creation__btn-wrap"},[_c('button',{staticClass:"button-green",attrs:{"type":"button"},on:{"click":_vm.addCategory}},[_vm._v(" Добавить ")])]),_c('button',{staticClass:"button-transponent",attrs:{"type":"button"},on:{"click":_vm.switchTabsClass}},[_vm._v(" Пропустить ")])])]),_c('div',{staticClass:"perfomance-category"},[_vm._m(0),_c('div',{staticClass:"divTable"},[_vm._m(1),(_vm.competitionGroupCateg && _vm.competitionGroupCateg.length)?_c('div',{staticClass:"divTableBody"},_vm._l((_vm.competitionGroupCateg),function(itemCateg){return _c('item-collect-competition',{key:itemCateg.id,attrs:{"item_categ":itemCateg},on:{"deleteCateg":_vm.deleteCateg}})}),1):_c('div',{},[_c('empty-block',{staticStyle:{"max-width":"100%"}})],1)])]),_c('div',{staticClass:"creation__btn"},[_c('button',{staticClass:"button",on:{"click":_vm.endRegistration}},[_vm._v(" Закончить регистрацию ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"perfomance-category__title"},[_c('p',{staticClass:"perfomance-category__title-text"},[_vm._v("собранные категории")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divTableHeading"},[_c('div',{staticClass:"divTableRow"},[_c('div',{staticClass:"divTableHead"},[_vm._v("Категория")]),_c('div',{staticClass:"divTableHead"},[_vm._v("Год рождения")]),_c('div',{staticClass:"divTableHead"},[_vm._v("Выполняемые виды")]),_c('div',{staticClass:"divTableHead"},[_vm._v("Выступают по разряду")])])])
}]

export { render, staticRenderFns }