<template>
  <table class="table">
    <thead>
      <tr>
        <th class="empty_th"></th>
        <th class="th__name">Ф.И.О.</th>
        <th class="th__trainer">Клуб</th>
      </tr>
    </thead>
    <tbody>
      <ItemTableCoach
        v-for="(coach, i) in this.coaches"
        :key="i"
        :item_coach="coach"
        @deleteCoach="deleteCoach"
        @editCoach="editCoach"
      />
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
// import { actionTypesEntered } from "@/store/modules/enteredData";
import ItemTableCoach from "./ItemTableCoach.vue";
export default {
  components: { ItemTableCoach },
  name: "AppTableCoach",
  data() {
    return {};
  },
  methods: {
    submitData() {
      this.$emit("checkCoach", this.checkedCoach);
    },
    addCoach() {
      this.$emit("addCoach");
    },
    editCoach(item) {
      this.$emit("editCoach", item);
    },
    deleteCoach(item) {
      // удаление тренеров
      this.$emit("deleteCoach", item, "COACH");
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.enteredData.isLoading,
      coaches: (state) => state.enteredData.allCoaches,
    }),
  },
};
</script>

<style></style>
