import registrationApi from "@/api/registration.js";

const state = {
  isLoading: false, //ожидание запроса
  isLoadingInfo: false,
  isLoadingRegJuj: false,
  isLoadingCreateApp: false, //ожидание создания заявки
  isLoadingAllApplication: false, //ожидание запроса получшения всех заявок
  validationErrorsAddGroup: null,
  allApplications: null, //все заявки
  allCompetition: null,


  selectRank: null,
  validationErrors: null,
  validationErrorsAddSportsmen: null,
  individualCategories: null,
  groupCategories: null,
  individualSportsmens: null,
  aboutRegistrApp: null,
  // //черновики заявок
  // draftIndividApp: [],
  // draftGroupApp: [],
};

export const mutationTypes = {
  // getRank
  getRankStart: "[registration] getRankStart",
  getRankSuccess: "[registration] getRankSuccess",
  getRankFailure: "[registration] getRankFailure",

  //getIndividualCategories
  getIndividualCategoriesStart: "[registration] getIndividualCategoriesStart",
  getIndividualCategoriesSuccess:
    "[registration] getIndividualCategoriesSuccess",
  getIndividualCategoriesFailure:
    "[registration] getIndividualCategoriesFailure",
  // getIndividualSportsmen
  getIndividualSportsmensStart: "[registration] getIndividualSportsmenSstart",
  getIndividualSportsmensSuccess:
    "[registration] getIndividualSportsmensSuccess",
  getIndividualSportsmensFailure:
    "[registration] getIndividualSportsmensFailure",
  // getInfoAboutRegistration
  getInfoAboutRegistrationAppStart:
    "[registration] getInfoAboutRegistrationAppSstart",
  getInfoAboutRegistrationAppSuccess:
    "[registration] getInfoAboutRegistrationAppSuccess",
  getInfoAboutRegistrationAppFailure:
    "[registration] getInfoAboutRegistrationAppFailure",
  // addSportsmenApp
  addSportsmenAppStart: "[registration] addSportsmenAppStart",
  addSportsmenAppSuccess: "[registration] addSportsmenAppSuccess",
  addSportsmenAppFailure: "[registration] addSportsmenAppFailure",
  // deleteSportsmenApp
  deleteSportsmenAppStart: "[registration] deleteSportsmenAppStart",
  deleteSportsmenAppSuccess: "[registration] deleteSportsmenAppSuccess",
  deleteSportsmenAppFailure: "[registration] deleteSportsmenAppFailure",
  // getGroupCategories
  getGroupCategoriesStart: "[registration] getGroupCategoriesStart",
  getGroupCategoriesSuccess: "[registration] getGroupCategoriesSuccess",
  getGroupCategoriesFailure: "[registration] getGroupCategoriesFailure",
  // addGroupApp
  addGroupAppStart: "[registration] addGroupAppStart",
  addGroupAppSuccess: "[registration] addGroupAppSuccess",
  addGroupAppFailure: "[registration] addGroupAppFailure",
  // deleteGroupApp
  deleteGroupAppStart: "[registration] deleteGroupAppStart",
  deleteGroupAppSuccess: "[registration] deleteGroupAppSuccess",
  deleteGroupAppFailure: "[registration] deleteGroupAppFailure",
  // addJujpApp
  addJujAppStart: "[registration] addJujAppStart",
  addJujAppSuccess: "[registration] addJujAppSuccess",
  addJujAppFailure: "[registration] addJujAppFailure",
  // deleteJujApp
  deleteJujAppStart: "[registration] deleteJujAppStart",
  deleteJujAppSuccess: "[registration] deleteJujAppSuccess",
  deleteJujAppFailure: "[registration] deleteJujAppFailure",
  // submitApplication
  submitApplicationStart: "[registration] submitApplicationStart",
  submitApplicationSuccess: "[registration] submitApplicationSuccess",
  submitApplicationFailure: "[registration]submitApplicationFailure",
  // allApplications
  allApplicationsStart: "[registration] allApplicationsStart",
  allApplicationsSuccess: "[registration] allApplicationsSuccess",
  allApplicationsFailure: "[registration] allApplicationsFailure",
};
export const actionTypes = {
  getIndividualCategories: "[registration] getIndividualCategories",
  getRank: "[registratin] getRank",
  getIndividualSportsmens: "[registration] getIndividualSportsmens",
  getInfoAboutRegistrationApp: "[registration] getInfoAboutRegistrationApp",
  addSportsmenApp: "[registration] addSportsmenApp",
  deleteSportsmenApp: "[registration] deleteSportsmenApp",
  getGroupCategories: "[registration] getGroupCategories",
  addGroupApp: "[registration] addGroupApp",
  deleteGroupApp: "[registration] deleteGroupApp",
  addJujApp: "[registration] addJujApp",
  deleteJujApp: "[registration] deleteJujApp",
  submitApplication: "[registration] submitApplication",
  allApplications: "[registration] allApplications",
};

const mutations = {
  // getIndividualCategories
  [mutationTypes.getIndividualCategoriesStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getIndividualCategoriesSuccess](state, info) {
    state.isLoading = false;
    state.individualCategories = info.result;
  },
  [mutationTypes.getIndividualCategoriesFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getIndividualSportsmen
  [mutationTypes.getIndividualSportsmensStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getIndividualSportsmensSuccess](state, info) {
    state.isLoading = false;
    state.individualSportsmens = info.result;
  },
  [mutationTypes.getIndividualSportsmensFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getRank
  [mutationTypes.getRankStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getRankSuccess](state, info) {
    state.isLoading = false;
    state.selectRank = info.result;
  },
  [mutationTypes.getRankFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getInfoAboutRegistration
  [mutationTypes.getInfoAboutRegistrationAppStart](state) {
    state.isLoadingInfo = true;
    state.validationErrors = null;
  },
  [mutationTypes.getInfoAboutRegistrationAppSuccess](state, info) {
    state.isLoadingInfo = false;
    state.aboutRegistrApp = info.result;
  },
  [mutationTypes.getInfoAboutRegistrationAppFailure](state, payload) {
    state.isLoadingInfo = false;
    state.validationErrors = payload;
  },
  // addSportsmenApp
  [mutationTypes.addSportsmenAppStart](state) {
    state.isLoading = true;
    state.validationErrorsAddSportsmen = null;
  },
  [mutationTypes.addSportsmenAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = info.result;
  },
  [mutationTypes.addSportsmenAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsAddSportsmen = payload;
  },
  // deleteSportsmenApp
  [mutationTypes.deleteSportsmenAppStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteSportsmenAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = info.result;
  },
  [mutationTypes.deleteSportsmenAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // getGroupCategories
  [mutationTypes.getGroupCategoriesStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.getGroupCategoriesSuccess](state, payload) {
    state.isLoading = false;
    state.groupCategories = payload.result;
  },
  [mutationTypes.getGroupCategoriesFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // addGroupApp
  [mutationTypes.addGroupAppStart](state) {
    state.isLoading = true;
    state.validationErrorsAddGroup = null;
  },
  [mutationTypes.addGroupAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = info.result;
  },
  [mutationTypes.addGroupAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrorsAddGroup = payload;
  },
  // deleteGroupApp
  [mutationTypes.deleteGroupAppStart](state) {
    state.isLoading = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteGroupAppSuccess](state, info) {
    state.isLoading = false;
    state.aboutRegistrApp = info.result;
  },
  [mutationTypes.deleteGroupAppFailure](state, payload) {
    state.isLoading = false;
    state.validationErrors = payload;
  },
  // addJujApp
  [mutationTypes.addJujAppStart](state) {
    state.isLoadingRegJuj = true;
    state.validationErrors = null;
  },
  [mutationTypes.addJujAppSuccess](state, info) {
    state.isLoadingRegJuj = false;
    state.aboutRegistrApp = info.result;
  },
  [mutationTypes.addJujAppFailure](state, payload) {
    state.isLoadingRegJuj = false;
    state.validationErrors = payload;
  },
  // deleteJujApp
  [mutationTypes.deleteJujAppStart](state) {
    state.isLoadingRegJuj = true;
    state.validationErrors = null;
  },
  [mutationTypes.deleteJujAppSuccess](state, info) {
    state.isLoadingRegJuj = false;
    state.aboutRegistrApp = info.result;
  },
  [mutationTypes.deleteJujAppFailure](state, payload) {
    state.isLoadingRegJuj = false;
    state.validationErrors = payload;
  },
  // submitApplication
  [mutationTypes.submitApplicationStart](state) {
    state.isLoadingCreateApp = true;
    state.validationErrorsAddSportsmen = null;
  },
  [mutationTypes.submitApplicationSuccess](state) {
    state.isLoadingCreateApp = false;
  },
  [mutationTypes.submitApplicationFailure](state, payload) {
    state.isLoadingCreateApp = false;
    state.validationErrorsAddSportsmen = payload;
  },
  // allApplications
  [mutationTypes.allApplicationsStart](state) {
    state.isLoadingAllApplication = true;
    state.validationErrors = null;
  },
  [mutationTypes.allApplicationsSuccess](state, info) {
    state.isLoadingAllApplication = false;
    state.allApplications = info.result;
  },
  [mutationTypes.allApplicationsFailure](state, payload) {
    state.isLoadingAllApplication = false;
    state.validationErrors = payload;
  },
};
const actions = {
  // getRank
  [actionTypes.getRank](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getRankStart);
      registrationApi
        .getRank()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.getRankSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.getRankFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getRankFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // individualCategories
  [actionTypes.getIndividualCategories](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getIndividualCategoriesStart);
      registrationApi
        .getIndividualCategories(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getIndividualCategoriesSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getIndividualCategoriesFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getIndividualCategoriesFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // getIndividualSportsmens
  [actionTypes.getIndividualSportsmens](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getIndividualSportsmensStart);
      registrationApi
        .getIndividualSportsmens(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getIndividualSportsmensSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getIndividualSportsmensFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getIndividualSportsmensFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // getInfoAboutRegistration
  [actionTypes.getInfoAboutRegistrationApp](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getInfoAboutRegistrationAppStart);
      registrationApi
        .getInfoAboutRegistrationApp(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getInfoAboutRegistrationAppSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getInfoAboutRegistrationAppFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getInfoAboutRegistrationAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // addSportsmenApp
  [actionTypes.addSportsmenApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addSportsmenAppStart);
      registrationApi
        .addSportsmenApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.addSportsmenAppSuccess, response.data);
            resolve(response.data);
          } else {
            console.log(response.data, 'ОШИБКА ДОБАВЛЕНИЯ')
            context.commit(mutationTypes.addSportsmenAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.addSportsmenAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // deleteSportsmenApp
  [actionTypes.deleteSportsmenApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteSportsmenAppStart);
      registrationApi
        .deleteSportsmenApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.deleteSportsmenAppSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.deleteSportsmenAppFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteSportsmenAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // getGroupCategories
  [actionTypes.getGroupCategories](context, id) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getGroupCategoriesStart);
      registrationApi
        .getGroupCategories(id)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getGroupCategoriesSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getGroupCategoriesFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getGroupCategoriesFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  //добавление группы в заявку
  [actionTypes.addGroupApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addGroupAppStart);
      registrationApi
        .addGroupApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.addGroupAppSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.addGroupAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.addGroupAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // deleteGroupApp
  [actionTypes.deleteGroupApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteGroupAppStart);
      registrationApi
        .deleteGroupApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.deleteGroupAppSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.deleteGroupAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteGroupAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // addJujApp
  [actionTypes.addJujApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.addJujAppStart);
      registrationApi
        .addJujApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.addJujAppSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.addJujAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.addJujAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // Удаление судей из заявки на соревнования
  [actionTypes.deleteJujApp](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.deleteJujAppStart);
      registrationApi
        .deleteJujApp(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.deleteJujAppSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.deleteJujAppFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.deleteJujAppFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // submitApplication
  [actionTypes.submitApplication](context, data) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.submitApplicationStart);
      registrationApi
        .submitApplication(data)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.submitApplicationSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.submitApplicationFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.submitApplicationFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  // allApplications Получение всех заявок
  [actionTypes.allApplications](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.allApplicationsStart);
      registrationApi
        .allApplications()
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.allApplicationsSuccess, response.data);
            resolve(response.data);
          } else {
            context.commit(mutationTypes.allApplicationsFailure, response.data);
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.allApplicationsFailure, err);
          console.log("ошибка", err);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
