import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import LottieAnimation from "lottie-web-vue";
import Vuelidate from "vuelidate";

import "./assets/scss/style.scss";

import "vue2-datepicker/index.css";

import "vue2-datepicker/locale/ru";
import moment from "moment";
import VMask from 'v-mask';

Vue.use(VMask);
Vue.prototype.$moment = moment;
Vue.use(LottieAnimation);
Vue.use(Vuelidate);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
