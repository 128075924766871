<template>
  <div style="width: 100%">
    <div class="accordion">
      <div class="accordion-header-people">
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">
            {{ selected.name }}
          </p>
          <p class="selected-text">Название</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">
            {{ selected.sportsmen.length }}
          </p>
          <p class="selected-text">Кол-во спортсменов</p>
        </div>
        <div class="accordion-header-people__block" v-if="selected.coachGroup">
          <p class="accordion-header-people__text">{{ selected.coachGroup }}</p>
          <p class="selected-text">Тренер</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSelectWithCheckGroup",
  data() {
    return {
      isOpen: false,
      selectWithCateg: {},
    };
  },
  computed: {
    newData() {
      return this.selected.dateOfBirth.split("-");
    },
    selected() {
      return this.checkSelect;
    },
  },
  props: {
    checkSelect: {
      type: Object,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    //  selectIndCateg: {
    //    type: Object,
    //    required: true,
    //  },
  },
  methods: {},
};
</script>

<style></style>
