<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(crumb, index) in breadcrumbs" :key="index">
        <router-link :to="crumb.link" class="breadcrumb-text">{{ crumb.label }}</router-link>
<!--        v-if="index !== 0 && breadcrumbs.length > 2"-->
        <img  src="../assets/svg/arrow-right.svg" class="breadcrumb-icon" alt="arrow">
      </li>
    </ol>
  </nav>
</template>

<script>
  export default {
    computed: {
      breadcrumbs() {
        // Получаем текущий путь из маршрута
        const currentRoute = this.$route.path;
        // Разбиваем текущий путь на отдельные сегменты
        const segments = currentRoute.split('/').filter(segment => segment !== '');

        // Формируем массив объектов с данными для хлебных крошек
        const breadcrumbs = segments.map((segment, index) => {
          if (segment === "competition") {
            // Если текущий сегмент "competition", заменяем его на "Соревнования"
            return { label: 'Соревнования', link: `/` };
          } else if (segment === 'edit-application') {
            return { label: 'Ред. заявки', link: `/${segments.slice(0, index + 1).join('/')}` };
          } else if(segment === 'new-application') {
            return { label: 'Создание заявки', link: `/${segments.slice(0, index + 1).join('/')}` };
          } else if (segment === 'mandated') {
            return { label: 'Мандатная', link: `/${segments.slice(0, index + 1).join('/')}` };
          } else {
            return { label: segment, link: `/${segments.slice(0, index + 1).join('/')}` };
          }
        });

        // Добавляем ссылку на домашнюю страницу
        breadcrumbs.unshift({ label: 'Главная', link: '/' });

        return breadcrumbs;
      }
    }
  }
</script>

<style>
/* Стили для хлебных крошек могут быть настроены по вашему усмотрению */
</style>
