var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"modal__cross",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("../../assets/svg/cross.svg"),"alt":"cross"}})])]),_c('form',{attrs:{"id":"#modalModificationSportsmen"},on:{"submit":function($event){$event.preventDefault();return _vm.safeInfoSportsmen.apply(null, arguments)}}},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-body__title"},[_c('p',{staticClass:"modal__title"},[_vm._v("Добавление спортсмена")])]),_c('div',{staticClass:"modal-scroll-max"},[_c('div',{staticClass:"input-wrap__row"},[_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Фамилия:")])]),_c('input',{staticClass:"input-light-text",class:{
                    'error-border-input':
                      _vm.isSubmitted && _vm.$v.putData.surname.$error,
                  },attrs:{"type":"text","placeholder":"Заполните фамилию","name":"surname"},domProps:{"value":_vm.item_sportsmen.surname}}),(_vm.isSubmitted && _vm.$v.putData.surname.$error)?_c('img',{staticClass:"wrapValidationInput__error",attrs:{"src":require("@/assets/svg/Error.svg"),"alt":"error"}}):_vm._e()]),_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Имя:")])]),_c('input',{staticClass:"input-light-text",class:{
                    'error-border-input':
                      _vm.isSubmitted && _vm.$v.putData.name.$error,
                  },attrs:{"type":"text","placeholder":"Заполните имя","name":"name"},domProps:{"value":_vm.item_sportsmen.name}}),(_vm.isSubmitted && _vm.$v.putData.name.$error)?_c('img',{staticClass:"wrapValidationInput__error",attrs:{"src":require("@/assets/svg/Error.svg"),"alt":"error"}}):_vm._e()])]),_c('div',{},[_c('div',{staticClass:"input-wrap"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Отчество:")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item_sportsmen.patronymic),expression:"item_sportsmen.patronymic"}],staticClass:"input-light-text",class:{
                    'error-border-input': _vm.isSubmitted && _vm.putData.patronymic.$error,
                  },attrs:{"type":"text","placeholder":"Заполните отчество","name":"patronymic"},domProps:{"value":(_vm.item_sportsmen.patronymic)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item_sportsmen, "patronymic", $event.target.value)}}}),(_vm.isSubmitted && _vm.$v.putData.patronymic.$error)?_c('img',{staticClass:"wrapValidationInput__error",attrs:{"src":require("@/assets/svg/Error.svg"),"alt":"error"}}):_vm._e()])]),_c('div',{staticClass:"input-wrap__row"},[_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Дата рождения:")])]),_c('input',{staticClass:"input-light-text",class:{
                    'error-border-input':
                      _vm.isSubmitted && _vm.$v.putData.dateOfBirth.$error,
                  },attrs:{"type":"date","name":"dateOfBirth","placeholder":"Время, место"},domProps:{"value":_vm.item_sportsmen.dateOfBirth}})]),_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Разряд при наличии:")])]),(this.selectRank !== null)?_c('custom-select-rank',{attrs:{"default":_vm.item_sportsmen.rank,"options":this.selectRank},on:{"input":_vm.safeRank}}):_vm._e()],1)]),_c('div',{staticClass:"modal-body__table"},[(_vm.isSubmitted && !_vm.$v.checkedCoachID.required)?_c('div',{staticClass:"validation"},[_c('p',{staticClass:"validation__text"},[_vm._v("*Назначьте тренера")])]):_vm._e(),_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Тренеры:")])]),_c('table-coach',{ref:"check",on:{"addCoach":_vm.addCoach,"checkedCoach":_vm.checkedCoach}})],1),_c('div',{staticClass:"input-wrap__row"},[_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Область/регион:")])]),_c('custom-select-universal',{attrs:{"default":_vm.item_sportsmen.region,"options":this.selectRegion},on:{"input":_vm.safeRegion}})],1),_c('div',{staticClass:"input-wrap__label-with-input-row"},[_c('div',{staticClass:"input-wrap__label"},[_c('p',{staticClass:"text-label"},[_vm._v("Город:")])]),_c('custom-select-universal',{attrs:{"default":_vm.item_sportsmen.city,"options":this.selectCity},on:{"input":_vm.safeCity}})],1)])])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"modal-footer__button"},[_c('button',{staticClass:"button"},[_vm._v("Сохранить")])]),_c('div',{staticClass:"modal-footer__button"},[_c('button',{staticClass:"button-transponent",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Отменить ")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }