<template>
  <div class="container new-login-page">
    <div class="background-index">
    <header class="new-login-page__header">
      <router-link to="/">
      <div class="header__logo">
        <img src="../assets/img/LogoRG-hor.png" class="header__logo-img" alt="logo">
      </div>
      </router-link>
      <div class="header__btn">
          <router-link to="/">
            <button class="login-btn background-green color-white ">На главную</button>
          </router-link>
      </div>
    </header>
    <div class="new-login-page__main">

      <div class="loader-overlay" v-if="isLoading">
        <div class="loader"></div>
      </div>
        <div class="new-login-page__left-50-block">
          <div class="new-login-page__mobile-registration">
            <div class="new-login-page__description">
              <p class="description"> У Вас нет аккаунта?   <router-link to="/registration"><span class="description-bold">Зарегистрироваться</span></router-link> </p>
            </div>
          </div>
          <div class="new-login-page__title">
            <p class="title-in-page">Вход</p>
          </div>
          <form @submit.prevent="onSubmit" class="submit-form">
            <ValidationErrors
                v-if="validationErrorsLogin"
                :validation-errors="validationErrorsLogin"
            />
            <fieldset class="submit-login__input-wrap">
              <p class="label-text" for="email">Почта</p>
              <input
                  type="text"
                  class="input-light-text"
                  placeholder="Введите адрес почты"
                  name="email"
                  v-model="email"
              />
            </fieldset>
            <fieldset class="submit-login__input-wrap-pass">
              <p class="label-text" for="password">Пароль</p>
              <div class="custom-input" v-show="isShowPassword">
                <input
                    type="password"
                    class="input-light-text"
                    placeholder="Введите пароль"
                    name="password"
                    v-model="password"
                />
                <img
                    src="~@/assets/svg/eye.svg"
                    alt="eye"
                    class="custom-input__img"
                    @click="isShowPass"
                />
              </div>
              <div class="custom-input" v-show="!isShowPassword">
                <input
                    type="text"
                    class="input-light-text"
                    placeholder="Введите пароль"
                    name="password"
                    v-model="password"
                />
                <img
                    src="~@/assets/svg/CloseEye.svg"
                    alt="eye"
                    class="custom-input__img-big"
                    @click="isShowPass"
                />
              </div>
            </fieldset>
            <div class="submit-login__button">
              <button class="button">Войти</button>
            </div>

            <div class="submit-login__fogot">
              <router-link to="/forgot-password"> <p class="submit-login__fogot-text">Забыли пароль?</p></router-link>
            </div>
          </form>
        </div>
      <div class="new-login-page__right-50-block">
        <div class="new-login-page__description">
          <p class="description"> У Вас нет аккаунта?   <router-link to="/registration"><span class="description-bold">Зарегистрироваться</span> </router-link></p></div>
        <div class="new-login-page__illustation">
          <img src="../assets/img/gym-illustration.png" alt="illustation">
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { actionTypes } from '@/store/modules/auth'
  import ValidationErrors from '@/components/ValidationErrors.vue'
  // import LottieAnimation from 'lottie-web-vue'
  // import { actionTypes } from "@/store/modules/auth";
  export default {
    name: "AppNewLogin",
    components: {  ValidationErrors },
    data() {
      return {
        email: "",
        password: "",
        isShowPassword: true,
      };
    },
    computed: {
      ...mapState({
        validationErrorsLogin: (state) => state.auth.validationErrorsLogin,
        isLoading: (state) => state.auth.isLoading,
      }),
    },
    methods: {
      onSubmit() {
        this.$store
            .dispatch(actionTypes.login, {
              email: this.email,
              password: this.password,
            })
            .then(() => {
              if (!this.validationErrors) {
                // if (response.result.userInfo.profileIsEmpty) {
                  this.$router.push({ name: "EnteredData" });
                // } else {
                //   this.$router.push({ name: "Profile" });
                // }
              }
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      },
      isShowPass() {
        this.isShowPassword = !this.isShowPassword;
      },
    },
  };
</script>
