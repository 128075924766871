<template>
  <div class="mandated-info__block">
    <div class="mandated-info__wrap">
    <div class="mandated-info__label">
      <p class="label-text-profile">
        Для просмотра групп подавших заявки выберите категорию
      </p>
    </div>
    <div class="mandated-info__select">
      <custom-select-category
        @input="getGroup"
        :default="defaultCategory"
        :options="listNominationGroup"
      />
    </div>
  </div>
    <div
      class="mandated-info__table"
      v-if="this.allGroups && this.allGroups.length !== 0"
    >
      <table class="table">
        <thead>
          <tr>
            <th class="th__name">№</th>
            <th class="th__name">Название группы</th>
            <th class="th__years">Спортсмены</th>
            <th class="th__years">Клуб</th>

            <th class="th__trainer">Тренеры</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(group, i) in allGroups">
            <tr v-for="(sportsman, j) in group.sportsman" :key="j" style="border-bottom: 2px solid rgb(205 205 200);">
              <!-- Первая ячейка только для первого спортсмена в группе -->
              <td
                v-if="j === 0"
                class="tb__checkbox"
                :rowspan="group.sportsman.length"
              >
                {{ i + 1 }}
              </td>
              <!-- Первая ячейка только для первого спортсмена в группе -->
              <td
                v-if="j === 0"
                class="tb__checkbox"
                :rowspan="group.sportsman.length"
              >
                {{ group.name }}
              </td>
              <!-- Ячейка со спортсменом -->
              <td class="tb__name">{{ sportsman.fio }}</td>
              <!-- Ячейки с остальными данными группы, только для первого спортсмена -->
              <td
                v-if="j === 0"
                class="tb__name"
                :rowspan="group.sportsman.length"
              >
                {{ group.city }}, {{ group.club }}
              </td>

              <td
                v-if="j === 0"
                class="tb__name"
                :rowspan="group.sportsman.length"
              >
                {{ group.coaches }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div
      class="mandated-info__table"
      v-if="this.allGroups && this.allGroups.length === 0"
    >
      <p class="mandated__item-block-text-active">
        В данной категории нет заявленных групп*
      </p>
    </div>
  </div>
</template>

<script>
import CustomSelectCategory from "../../components/customSelect/CustomSelectCategory.vue";
import { mapState } from "vuex";
import { actionTypesMandated } from "@/store/modules/mandated";
export default {
  components: { CustomSelectCategory },
  name: "AppInfoAboutGroups",
  // allGroups
  data() {
    return {
      dataIdToGroup: null,
      defaultCategory: "Выберите категорию",
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.mandated.isLoading,
      allGroups: (state) => state.mandated.allGroups,
    }),
  },
  props: {
    listNominationGroup: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  methods: {
    getGroup(category) {
      this.dataIdToGroup = {
        categoryId: category.id,
        competitionId: this.$route.params.id,
      };
      this.$store
        .dispatch(actionTypesMandated.getAllGroupCateg, this.dataIdToGroup)
        .then(() => {})
        .catch((er) => {
          console.log(er, "Error");
        });
    },
  },
};
</script>

<style></style>
