<template>
  <table class="table">
    <thead>
      <tr>
        <th class="empty_th"></th>
        <th class="th__name">Ф.И.О.</th>
        <th class="th__years">Год р.</th>
        <th class="th__section">Разряд</th>
        <th class="th__trainer">Тренеры</th>
      </tr>
    </thead>
    <tbody>
      <template>
        <item-table-sportsmen
          v-for="(sportsmen, i) in this.allSportsmens"
          :key="i"
          :item_sportsmen="sportsmen"
          @deleteSportsmen="deleteSportsmen"
          @editSportsmen="editSportsmen"
        />
      </template>
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
import ItemTableSportsmen from "./ItemTableSportsmen.vue";
// import { actionTypesEntered } from "@/store/modules/enteredData";
export default {
  components: { ItemTableSportsmen },
  name: "AppTableSportsmen",
  data() {
    return {};
  },
  methods: {
    submitData() {
      this.$emit("checkCoach", this.checkedCoach);
    },
    addCoach() {
      this.$emit("addCoach");
    },
    editSportsmen(item) {
      this.$emit("editSportsmen", item);
    },
    deleteSportsmen(item) {
      //Назначаем объект для удадения и роль
      this.$emit("deleteSportsmen", item, "SPORTSMAN");
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      allSportsmens: (state) => state.enteredData.allSportsmens,
    }),
  },
};
</script>

<style></style>
