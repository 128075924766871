<template>
  <tr>
    <td class="edit__btn">
      <img
        class="icon__edit"
        src="../../assets/svg/pain.svg"
        alt="Edit"
        @click="editGroup()"
      />
      <img
        class="icon__delete"
        src="../../assets/svg/trash.svg"
        alt="Delete"
        @click="deleteGroup()"
      />
    </td>

    <td class="td__open-group" @click="openSelect()">
      <div class="open-group">
        <img
          src="../../assets/svg/ArrowDown.svg"
          alt="down"
          class="open-group__img"
        />
        <p class="open-group__text">{{ this.item_group.name }}</p>
      </div>
      <div class="group-list" v-if="this.isOpenSelect">
        <table class="table">
          <thead>
            <tr>
              <th class="th__name">Ф.И.О.</th>
              <th class="th__years">Год р.</th>
              <th class="th__section">Разряд</th>
              <th class="th__trainer">Тренеры</th>
            </tr>
          </thead>
          <tbody>
            <item-sportsmen-in-table-group
              v-for="(sportsmen, i) in this.item_group.sportsmen"
              :key="i"
              :item_sportsmen="sportsmen"
            />
          </tbody>
        </table>
      </div>
    </td>
    <td style="display: flex;">{{ this.item_group.coachGroup }}</td>
  </tr>
</template>

<script>
import ItemSportsmenInTableGroup from "./ItemSportsmenInTableGroup.vue";
export default {
  components: { ItemSportsmenInTableGroup },
  name: "AppItemTableGroup",
  data() {
    return {
      checkedP: false,
      isOpenSelect: false,
    };
  },
  props: {
    item_group: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    checkedCoach: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(val, "val");
        this.checkedP = val;
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheck");
    },
    deleteGroup() {
      this.$emit("deleteGroup", this.item_group);
    },
    editGroup() {
      this.$emit("editGroup", this.item_group);
    },
    openSelect() {
      this.isOpenSelect = !this.isOpenSelect;
    },
  },
};
</script>

<style></style>
