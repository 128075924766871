import createStartList from "@/api/createStartList.js";

const state = {
  isLoading: false, //ожидание запроса
  validationErrors: null, //ошибки
  aboutCompetitionForStartList: null,
  listNomination: [],
  listGroupNomination: [],
  newList: [],
  newListGroup: [],
};

export const mutationTypes = {
  aboutCompStartListStart: "[createStartList] aboutCompStartListStart",
  aboutCompStartListSuccess: "[createStartList] aboutCompStartListSuccess",
  aboutCompStartListFailure: "[createStartList] aboutCompStartListFailure",

  getNominationsListStart: "[createStartList]  getNominationsListStart",
  getNominationsListSuccess: "[createStartList]  getNominationsListSuccess",
  getNominationsListFailure: "[createStartList]  getNominationsListFailure",

  getNominationGroupListStart: "[createStartList]  getNominationGroupListStart",
  getNominationGroupListSuccess:
    "[createStartList]  getNominationGroupListSuccess",
  getNominationGroupListFailure:
    "[createStartList]  getNominationGroupListFailure",

  postAllInfoAboutStartListStart:
    "[createStartList] postAllInfoAboutStartListStart",
  postAllInfoAboutStartListSuccess:
    "[createStartList] postAllInfoAboutStartListSuccess",
  postAllInfoAboutStartListFailure:
    "[createStartList] postAllInfoAboutStartListFailure",
};
export const actionTypesStartList = {
  aboutCompStartList: "[createStartList] aboutCompStartList",
  getNominationsList: "[createStartList] getNominationsList",
  postAllInfoAboutStartList: " [createStartList] postAllInfoAboutStartList",
  getNominationGroupList: "[createStartList] getNominationGroupList",
};

const mutations = {
  [mutationTypes.aboutCompStartListStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.aboutCompStartListSuccess](state, response) {
    state.aboutCompetitionForStartList = response.result;
    state.isLoading = false;
  },
  [mutationTypes.aboutCompStartListFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoading = false;
  },

  [mutationTypes.getNominationsListStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getNominationsListSuccess](state, response) {
    state.isLoading = false;
    state.listNomination = response.result;
    state.newList = state.listNomination.map((itemElem) => [
      ...itemElem.performedTypes.map((item) => {
        return {
          // ...item,
          name:
            "Вид: " +
            item.name +
            "; Возраст: " +
            itemElem.individualCategory.fromAge +
            "-" +
            itemElem.individualCategory.beforeAge +
            " " +
            "Категория " +
            itemElem.individualCategory.individualCategory,
          idCategory: itemElem.individualCategory.id,
          fromAge: itemElem.individualCategory.fromAge,
          beforeAge: itemElem.individualCategory.beforeAge,
          individualCategory: itemElem.individualCategory.individualCategory,
          category: item.name,
          order: null,
          union: false,
          type: "Индивидуальная",
        };
      }),
    ]);
    state.newList = [].concat.apply([], state.newList);
  },
  [mutationTypes.getNominationsListFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoading = false;
  },

  [mutationTypes.getNominationGroupListStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.getNominationGroupListSuccess](state, response) {
    state.isLoading = false;
    state.listGroupNomination = response.result;
    state.newListGroup = state.listGroupNomination.map((itemElem) => [
      ...itemElem.performedTypes.map((item) => {
        return {
          name:
            "Вид: " +
            item.name +
            "; Возраст: " +
            itemElem.groupCategory.fromAge +
            "-" +
            itemElem.groupCategory.beforeAge +
            " " +
            "Категория " +
            itemElem.groupCategory.individualCategory,
          idCategory: itemElem.groupCategory.id,
          fromAge: itemElem.groupCategory.fromAge,
          beforeAge: itemElem.groupCategory.beforeAge,
          individualCategory: itemElem.groupCategory.groupCategory,
          category: item.name,
          order: null,
          union: false,
          type: "Группы",
        };
      }),
    ]);
    state.newListGroup = [].concat.apply([], state.newListGroup);
  },
  [mutationTypes.getNominationGroupListFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoading = false;
  },

  [mutationTypes.postAllInfoAboutStartListStart](state) {
    state.validationErrors = null;
    state.isLoading = true;
  },
  [mutationTypes.postAllInfoAboutStartListSuccess](state) {
    state.isLoading = false;
  },
  [mutationTypes.postAllInfoAboutStartListFailure](state, payload) {
    state.validationErrors = payload;
    state.isLoading = false;
  },
};
const actions = {
  [actionTypesStartList.aboutCompStartList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.aboutCompStartListStart);
      createStartList
        .aboutCompStartList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.aboutCompStartListSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.aboutCompStartListFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.aboutCompStartListFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypesStartList.getNominationsList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNominationsListStart);
      createStartList
        .getNominationsList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getNominationsListSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getNominationsListFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getNominationsListFailure, err);
          console.log("ошибка", err);
        });
    });
  },

  [actionTypesStartList.getNominationGroupList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getNominationGroupListStart);
      createStartList
        .getNominationGroupList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(
              mutationTypes.getNominationGroupListSuccess,
              response.data
            );
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.getNominationGroupListFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.getNominationGroupListFailure, err);
          console.log("ошибка", err);
        });
    });
  },
  [actionTypesStartList.postAllInfoAboutStartList](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.postAllInfoAboutStartListStart);
      createStartList
        .postAllInfoAboutStartList(credentials)
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            context.commit(mutationTypes.postAllInfoAboutStartListSuccess);
            resolve(response.data);
          } else {
            context.commit(
              mutationTypes.postAllInfoAboutStartListFailure,
              response.data
            );
          }
        })
        .catch((err) => {
          context.commit(mutationTypes.postAllInfoAboutStartListFailure, err);
          console.log("ошибка", err);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
