<template>
  <table class="table">
    <thead>
      <tr>
        <th class="empty_th"></th>
        <th class="th__name">Ф.И.О.</th>
        <th class="th__trainer">Категория</th>
      </tr>
    </thead>
    <tbody>
      <ItemTableJuj
        v-for="(juj, i) in this.judges"
        :key="i"
        :item_juj="juj"
        @deleteJuj="deleteJuj"
        @editJuj="editJuj"
      />
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
// import { actionTypesEntered } from "@/store/modules/enteredData";
import ItemTableJuj from "./ItemTableJuj.vue";
// import ItemTableCoach from "./ItemTableCoach.vue";
export default {
  components: { ItemTableJuj },
  name: "AppTableJuj",
  data() {
    return {};
  },
  methods: {
    editJuj(item) {
      this.$emit("editJuj", item);
    },
    deleteJuj(item) {
      this.$emit("deleteJuj", item, "JUDGE");
      // удаление судьи
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.enteredData.isLoading,
      judges: (state) => state.enteredData.allJuj,
    }),
  },
};
</script>

<style></style>
