<template>
  <div style="width: 100%">
    <div class="accordion" :class="{ 'is-open': isOpen }">
      <div
        class="accordion-header-placeholder"
        :style="{ background: this.color }"
        @click="toggleAccordion"
        v-if="selected === this.default"
      >
        {{ selected }}
      </div>

      <div class="accordion-body items" ref="body">
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="safeSelect(option), toggleAccordion()"
        >
          {{ option.name }} / {{ option.sportsmen.length }}
        </div>
        <div class="add" @click="showModalAddGroup(), toggleAccordion()">
          <img src="../../assets/svg/plus.svg" alt="plus" />
          <p class="add-text">Добавить группу</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppCustomSelectAppGroup",
  data() {
    return {
      isOpen: false,
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      selectWithCateg: {},
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    selectGroupCateg: {
      type: Object,
      required: false,
    },
    color: {
      type: String,
    },
  },
  methods: {
    safeSelect(option) {
      this.selected = option;
      if (this.selectGroupCateg) {
        this.selectWithCateg = {
          categ: this.selectGroupCateg,
          group: option,
        };

        this.$emit("input", this.selectWithCateg);
        this.selected = this.default;
        this.selectWithCateg = {};
      } else {
        this.$emit("input", this.selected);
        this.selected = this.default;
      }
    },
    showModalAddGroup() {
      this.$emit("showModalAddGroup");
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.animateOpen();
      } else {
        this.animateClose();
      }
    },
    animateOpen() {
      const body = this.$refs.body;
      const height = body.scrollHeight;
      body.style.height = "0";
      setTimeout(() => {
        body.style.height = `${height}px`;
      }, 5);
    },
    animateClose() {
      const body = this.$refs.body;
      body.style.height = `${body.scrollHeight}px`;
      setTimeout(() => {
        body.style.height = "0";
      }, 5);
    },
  },
};
</script>

<style></style>
