import axios from "@/api/axios";

const getAllInfoMandated = (idCompetition) => {
  return axios.get(`/get/mandate/${idCompetition}`);
};
const getAllSportsmenIndCateg = (info) => {
  return axios.get(
    `/get/mandate/all_sportsmen_by_individual_category/${info.categoryId}/${info.competitionId}`
  );
};
const getAllGroupCateg = (info) => {
  return axios.get(
    `/get/mandate/all_sportsmen_by_group_category/${info.categoryId}/${info.competitionId}`
  );
};
const sortition = (id) => {
  return axios.post(`draw/sportsman?competitionId=${id}`);
};
export default {
  getAllInfoMandated,
  getAllSportsmenIndCateg,
  getAllGroupCateg,
  sortition,
};
