<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form @submit.prevent="safeInfoJuj">
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Добавление судьи</p>
              </div>
              <ValidationErrors
                  v-if="validationErrorsJuj"
                  :validation-errors="validationErrorsJuj.message"
              />
              <div class="modal-scroll-max">

              <div
                class="message-about-empty empty"
                v-if="isSubmitted && this.$v.$invalid"
              >
                <p class="message-about-empty__text">Заполните все данные!</p>
              </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row-3">
                  <div class="input-wrap__label">
                    <p class="label-text">Фамилия:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    placeholder="Фамилия"
                    :class="{
                      'error-border-input': isSubmitted && $v.surname.$error,
                    }"
                    name="surname"
                    v-model="surname"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.surname.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-row-3">
                  <div class="input-wrap__label">
                    <p class="label-text">Имя:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input': isSubmitted && $v.name.$error,
                    }"
                    placeholder="Имя"
                    name="name"
                    v-model="name"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.name.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-row-3">
                  <div class="input-wrap__label">
                    <p class="label-text">Отчество:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    placeholder="Отчество"
                    :class="{
                      'error-border-input': isSubmitted && $v.patronymic.$error,
                    }"
                    name="patronymic"
                    v-model="patronymic"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.patronymic.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>
              <div class="input-wrap__column">
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="label-text">Судейская категория:</p>
                  </div>
                  <custom-select-rank
                    @input="safeRank"
                    :class="{
                      'error-border-input': isSubmitted && $v.rank.$error,
                    }"
                    :default="this.defaultCateg"
                    :options="this.selectJujCateg"
                  />
                  <!-- <custom-select @input="safeRank" :options="this.selectRank" /> -->
                </div>
              </div>
              <div class="input-wrap__column">
                <div v-if="isSubmitted && $v.phone.$error" class="validation">
                  <span
                    class="validation__text"
                    v-if="!$v.phone.minLength || !$v.phone.maxLength || $v.phone.$error"
                    >*Номер телефона должен состоять из 12 символов</span
                  >
                </div>
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="label-text">Телефон:</p>
                  </div>
                  <input
                      type="tel"
                      class="input-light-text"
                      :class="{'error-border-input': isSubmitted && $v.phone.$error}"
                      placeholder="+7 (***)***-****"
                      name="phone"
                      v-model="phone"
                      v-mask="'+7##########'"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.phone.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>
              <div class="input-wrap__row">
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="label-text">Область/регион:</p>
                  </div>
                  <custom-select-universal
                    @input="safeRegion"
                    :class="{
                      'error-border-input': isSubmitted && $v.region.$error,
                    }"
                    :default="this.defaultRegion"
                    :options="this.selectRegion"
                  />
                </div>
                <div class="input-wrap__label-with-input-row">
                  <div class="input-wrap__label">
                    <p class="label-text">Город:</p>
                  </div>
                  <custom-select-universal
                    @input="safeCity"
                    :class="{
                      'error-border-input': isSubmitted && $v.city.$error,
                    }"
                    :default="this.defaultCity"
                    :options="this.selectCity"
                  />
                </div>
              </div>
            </div>

            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="button">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button
                    type="button"
                    class="button-transponent"
                    @click="$emit('close')"
                >
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { actionTypesEntered } from "@/store/modules/enteredData";
import CustomSelectUniversal from "../customSelect/CustomSelectUniversal.vue";
import { mapState } from "vuex";
import CustomSelectRank from "../customSelect/CustomSelectRank.vue";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import ValidationErrors from '@/components/ValidationErrors.vue'
export default {
  name: "AppModalAddJuj",
  data() {
    return {
      name: "",
      surname: "",
      patronymic: "",
      phone: "",
      rank: "",
      city: "",
      region: "",
      selectRegion: ["Новосибирская область"],
      selectJujCateg: [
        { id: 1, name: "1 категория" },
        { id: 2, name: "2 категория" },
        { id: 3, name: "3 категория" },
        { id: 4, name: "ССВК" },
        { id: 5, name: "юный судья" },
        { id: 6, name: "б/к" },
      ],
      selectCity: ["Новосибирск","Краснообск", "Бердск", "Академгородок"],
      defaultRegion: "Выберите регион",
      defaultCity: "Выберите город",
      defaultCateg: "Выберите категорию",
      isSubmitted: false,
    };
  },
  validations: {
    name: {
      required,
    },
    surname: {
      required,
    },
    patronymic: {
      required,
    },
    rank: {
      required,
    },
    phone: {
      required,
      minLength: minLength(12),
      maxLength: maxLength(12),
    },
    city: {
      required,
    },
    region: {
      required,
    },
  },
  components: {
    ValidationErrors,
    CustomSelectUniversal,
    CustomSelectRank,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      selectRank: (state) => state.registration.selectRank,
      validationErrorsJuj: state => state.enteredData.validationErrorsJuj
    }),
  },
  methods: {
    safeCity(item) {
      console.log(item, "city");
      this.city = item;
    },
    safeRegion(item) {
      console.log(item, "region");
      this.region = item;
    },
    safeRank(item) {
      console.log(item, "rank");
      this.rank = item;
    },
    safeInfoJuj() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return console.log("Данные не заполенены");
      } else {
        this.$store
          .dispatch(actionTypesEntered.registrJuj, {
            name: this.name,
            surname: this.surname,
            phone: this.phone,
            patronymic: this.patronymic,
            region: this.region,
            city: this.city,
            category: this.rank.name,
          })
          .then(() => {
            this.$emit("close");
            this.name = "";
            this.surname = "";
            this.patronymic = "";
            this.region = "";
            this.city = "";
            this.phone = "";
            this.rank = "";
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
  },
};
</script>

<style></style>
