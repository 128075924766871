<template>
  <div id="app" class="app">
    <Sidebar v-if="infoUser !== null" />
    <router-view />
<!--    Поддержка-->
<!--    <Footer />-->
  </div>
</template>

<script>
// import Footer from "./components/Footer.vue";
import Sidebar from "./components/Sidebar.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    Sidebar,
    // Footer,
  },
  computed: {
    ...mapState({
      infoUser: (state) => state.auth.infoUser,
    }),
  },
};
</script>
<style lang="scss">
:root {
  --primary: #4ade80;
  --primary-alt: #22c55e;
  --grey: #64748b;
  --dark: #1e293b;
  --dark-alt: #334155;
  --light: #f1f5f9;
  --menu-text: #2d0b00;
  --sidebar-width: 312px;
}

.app {
  display: flex;
  main {
    flex: 1 1 0;
    padding: 2rem;
    @media (max-width: 1024px) {
      padding-left: 6rem;
    }
  }
}
</style>
