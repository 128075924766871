import axios from "@/api/axios";

//Регистрация соревнований(получение id)
const competitionsReg = () => {
  return axios.post(`/competitions/reg`);
};

//Получение всех соревнований юзера
const allUserCompetition = () => {
  return axios.get(`/all/user/competition`);
};
//Удаление соревнования юзера
const deleteCompetition = (id) => {
  return axios.post(`/competitions/delete/${id}`);
};
//!ПЕРВЫЙ ЭТАП

//Получение возможных индивидуальных категорий
const individualCategory = () => {
  return axios.get(`/individual/category`);
};
//Первый этап регистрации(заполненные поля)
const competitionsRegFirst = (data) => {
  return axios.post(`/competitions/reg/first/step`, data);
};
//Получение информации из первого этапа
const getCompetitionItem = (competitionId) => {
  return axios.get(`/get/competition/fist/step/${competitionId}`);
};
//Загрузка фотографии(постера)

const addPhoto = (dataWithId) => {
  return axios.post(
    `/competitions/save/image/${dataWithId.id}`,
    dataWithId.formData
  );
};

//!ВТОРОЙ ЭТАП

//Второй этап регистрации(регистрация каждой категории)
const competitionsRegSecondStep = (data) => {
  return axios.post(`/competitions/reg/second/step`, data);
};
//Второй этап регистрации(удаление каждой категории)
const competitionsDeleteSecondStep = (data) => {
  return axios.post(
    `/competitions/individual/${data.id}/${data.competitionId}`
  );
};
//Второй этап регистрации(Получение всех категорий)
const competitionAllIndividual = (competitionId) => {
  return axios.get(`/competitions/individual/${competitionId}`);
};

//!ТРЕТИЙ ЭТАП

// Регистрация категории в третьем этапе
const competitionsRegTrirdStep = (data) => {
  return axios.post(`/competitions/reg/third/stage`, data);
};
// Получение всех номинаций и разрядов
const groupCategory = () => {
  return axios.get(`/group/category`);
};
// Получение всех зареганных категорий в черновике
const getGroupCategoryDraft = (competitionId) => {
  return axios.get(`/get/group/category/competition/${competitionId}`);
};
// Удаление всех зареганных категорий в черновике
const competitionsDeleteTrirdStep = (info) => {
  return axios.post(
    `/competitions/delete/group/${info.id}/${info.competitionId}`
  );
};

//! ОКОНЧАНИЕ РЕГИСТРАЦИИ
// Завершение регистрации
const endCompetition = (competitionId) => {
  return axios.post(`/competitions/end/registration/${competitionId}`);
};
export default {
  individualCategory,
  competitionsReg,
  deleteCompetition,
  allUserCompetition,
  competitionsRegFirst,
  competitionsRegSecondStep,
  competitionsDeleteSecondStep,
  competitionAllIndividual,
  getCompetitionItem,
  competitionsRegTrirdStep,
  groupCategory,
  getGroupCategoryDraft,
  competitionsDeleteTrirdStep,
  endCompetition,
  addPhoto,
};
