<template>
  <div class="competition__item">
    <div
      :class="[
        this.item_app.isFinished || this.item_app.isEndRegistration
          ? 'header__block-brown'
          : 'header__block',
      ]"
    >
      <!-- header__block-draft -->
      <p class="header__text">Заявка #{{ this.item_app.id }}</p>
    </div>
    <div class="info-about draft">
      <div class="">
        <div class="info-about__title">
          <p class="info-about__title-text">
            {{ this.item_app.title }}
          </p>
        </div>
        <div class="info-about__icon">
        <div class="info-about__icon-length" v-if="this.item_app && this.item_app.sportsmans.length">
          <img src="../../assets/svg/user-dark.svg" alt="user" />
          <p class="title-description__title">{{this.item_app.sportsmans.length}} Инд.</p>
        </div>
          <div class="info-about__icon-length" v-if="this.item_app && this.item_app.groups.length">
            <img src="../../assets/svg/groupUser.svg" alt="user" />
            <p class="title-description__title">{{this.item_app.groups.length}} Групп.</p>
          </div>
          <div class="info-about__icon-length" v-if="this.item_app && this.item_app.judges.length">
            <img src="../../assets/svg/auction.svg" alt="user" />
            <p class="title-description__title">{{this.item_app.judges.length}} Суд.</p>
          </div>
        </div>
        <div class="validation" v-if="this.item_app.isEndRegistration">
          <p class="validation__text">
            *Прием заявок окончен, добавление спортсменов невозможно!
          </p>
        </div>
        <div class="info-about__description">
          <div class="title-description">
            <p class="title-description__title">Дата создания заявки:</p>
            <p class="title-description__text">
              {{ this.dateOfCreationMoment }}
            </p>
          </div>
          <div class="title-description">
            <p class="title-description__title">Последнее изменение:</p>
            <p class="title-description__text">{{ this.dateOfEditMoment }}</p>
          </div>
        </div>
      </div>
      <div class="competition__item-wrap">
        <div class="competition__item-btn">
          <button class="button" @click="openModalApp">
            <img
              src="../../assets/svg/eyeWhite.svg"
              alt="eye"
              class="button__img"
            />
            Посмотреть
          </button>
        </div>
        <!-- isEndRegistration -->
        <template v-if="!this.item_app.isFinished">
          <div
            class="competition__item-btn"
            v-if="!this.item_app.isEndRegistration"
          >
            <router-link
              :to="{
                name: 'New-Application',
                params: { id: this.item_app.idCompetition },
              }"
            >
              <button class="button-green">Изменить</button>
            </router-link>
          </div>
          <div class="competition__item-btn" v-else>
            <router-link
              :to="{
                name: 'Edit-Application',
                params: { id: this.item_app.idCompetition },
              }"
            >
              <button class="button-green">Редактировать</button>
            </router-link>
          </div>
          <!-- <div
            class="competition__item-trash"
            v-if="!this.item_app.isEndRegistration"
          >
            <img
              src="../../assets/svg/trash.svg"
              alt="delete"
              @click="deleteApplication"
            />
          </div> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import { actionTypes } from "@/store/modules/registrationComp";
export default {
  name: "AppItemApplication",
  data() {
    return {};
  },
  props: {
    item_app: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    dateOfEditMoment() {
      return this.$moment(this.item_app.editTime).locale("ru").format("LLL");
    },
    dateOfCreationMoment() {
      return this.$moment(this.item_app.createTime).locale("ru").format("LLL");
    },
  },
  methods: {
    //Удаление заявки
    // deleteApplication() {
    //   this.$store
    //     .dispatch(actionTypes.deleteCompetition, this.item_app.id)
    //     .then(() => {
    //       this.$store
    //         .dispatch(actionTypes.allUserCompetition)
    //         .then(() => {})
    //         .catch((er) => {
    //           console.log("Ошибка", er);
    //         });
    //     })
    //     .catch((er) => {
    //       console.log("Ошибка", er);
    //     });
    // },
    openModalApp() {
      this.$emit("openModalApp", this.item_app);
    },
    //  continueDraft() {
    //    this.$store
    //      .dispatch(actionTypes.getCompetitionItem, this.item_comp.id)
    //      .then(() => {})
    //      .catch((er) => {
    //        console.log("Ошибка", er);
    //      });
    //  },
  },
};
</script>

<style></style>
