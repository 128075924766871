<template>
  <div class="creation">
    <Header message="Ваши соревнования" />
    <ul id="progressbar">
      <li class="active" @click="switchTabsClass('indexInfo')">
        Регистрация соревнований
      </li>
      <!-- :class="{ notactive: this.currentUser === null }" -->
      <li id="choice-weapons" @click="switchTabsClass('individual')">
        Добавление индивидуальных категорий
      </li>
      <!-- :class="{
          notactive: this.getWeaponItem === null,
        }" -->
      <li
        id="active-block"
        :class="{ active: this.isBlockGroup }"
        @click="switchTabsClass('group')"
      >
        Добавление групповых категорий
      </li>
    </ul>
    <index-info-about-competition
      v-if="this.isBlockInfoComp && this.competitionItemInfo == null"
      @switchTabsClass="switchTabsClass"
      v-bind:id="id"
    />
    <draft-index-info-about-competition
      v-bind:id="id"
      v-if="this.isBlockInfoComp && this.competitionItemInfo !== null"
      @switchTabsClass="switchTabsClass"
    />
    <IndividualPerformance
      v-bind:id="id"
      v-if="this.isBlockIndividule"
      @switchTabsClass="switchTabsClass"
    />
    <GroupPerformance
      v-bind:id="id"
      v-if="this.isBlockGroup"
      @switchTabsClass="switchTabsClass"
    />
  </div>
</template>

<script>
import DraftIndexInfoAboutCompetition from "../components/DraftIndexInfoAboutCompetition.vue";
import Header from "../components/Header.vue";
import IndexInfoAboutCompetition from "../components/IndexInfoAboutCompetition.vue";
import IndividualPerformance from "../components/IndividualPerformance.vue";
import { actionTypes } from "@/store/modules/registrationComp";
import { mapState } from "vuex";
import GroupPerformance from "../components/GroupPerformance.vue";
export default {
  name: "AppCreateCompetition",
  data() {
    return {
      isBlockInfoComp: true,
      isBlockIndividule: false,
      isBlockGroup: false,
    };
  },
  props: ["id"],
  components: {
    Header,
    IndexInfoAboutCompetition,
    IndividualPerformance,
    DraftIndexInfoAboutCompetition,
    GroupPerformance,
  },
  computed: {
    ...mapState({
      competitionItemInfo: (state) =>
        state.registrationComp.competitionItemInfo,
    }),
  },
  methods: {
    switchTabsClass(tab) {
      if (tab === "individual") {
        this.isBlockInfoComp = false;
        this.isBlockIndividule = true;
        this.isBlockGroup = false;
        let elem = document.getElementById("choice-weapons");
        elem.classList.add("active");
      } else if (tab === "group") {
        this.isBlockInfoComp = false;
        this.isBlockIndividule = false;
        this.isBlockGroup = true;
        let elemSecond = document.getElementById("choice-weapons");
        elemSecond.classList.add("active");
        let elemThird = document.getElementById("active-block");
        elemThird.classList.add("active");
      } else {
        this.isBlockInfoComp = true;
        this.isBlockIndividule = false;
        this.isBlockGroup = false;
      }
    },
  },
  mounted() {
    this.$store
      .dispatch(actionTypes.getCompetitionItem, this.id)
      .then(() => {})
      .catch((er) => {
        console.log("Ошибка", er);
      });
  },
};
</script>

<style></style>
