<template>
  <tr>
    <td class="edit__btn">
      <img
        class="icon__edit"
        src="../../assets/svg/pain.svg"
        alt="Edit"
        @click="editCoach()"
      />
      <img
        class="icon__delete"
        src="../../assets/svg/trash.svg"
        alt="Delete"
        @click="deleteCoach()"
      />
    </td>

    <td class="tb__checkbox">
      {{ item_coach.surname }} {{ item_coach.coachName }}
      {{ item_coach.patronymic }}
    </td>
    <td class="tb__name">
      {{ item_coach.club }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "AppItemTableSportsmen",
  data() {
    return {
      checkedP: false,
    };
  },
  props: {
    item_coach: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    checkedCoach: {
      get() {
        return this.value;
      },
      set(val) {
        console.log(val, "val");
        this.checkedP = val;
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedP);
      this.$emit("updateCheck");
    },
    deleteCoach() {
      this.$emit("deleteCoach", this.item_coach);
    },
    editCoach() {
      this.$emit("editCoach", this.item_coach);
    },
  },
};
</script>

<style></style>
