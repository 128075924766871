<template>
  <tr>
    <td class="tb__name">
      {{ item_juj.surname }} {{ item_juj.name }} {{ item_juj.patronymic }}
    </td>
    <td class="tb__name">
      {{ item_juj.category }}
    </td>
  </tr>
</template>

<script>
export default {
  name: "AppItemTableAppJudge",
  data() {
    return {
      checkedP: false,
    };
  },
  props: {
    item_juj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style></style>
