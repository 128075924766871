<template>
  <div style="width: 100%">
    <div class="accordion">
      <div class="accordion-header-people" style="padding: 10px 0px 20px 0px">
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">
            {{ selected.surname }} {{ selected.name }}
            {{ selected.patronymic }}
          </p>
          <p class="selected-text">ФИО</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">{{ selected.category }}</p>
          <p class="selected-text">Судейская категория</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSelectWithCheckJuj",
  data() {
    return {
      isOpen: false,

      selectWithCateg: {},
    };
  },
  computed: {
    selected() {
      return this.checkSelect;
    },
  },
  props: {
    checkSelect: {
      type: Object,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {},
};
</script>

<style></style>
