import axios from "@/api/axios";

const profileInfo = () => {
  return axios.get(`/profile`);
};
const editInfoProfile = (info) => {
  return axios.post(`/edit/profile`, info);
};

export default {
  profileInfo,
  editInfoProfile,
};
