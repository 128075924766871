<template>
  <div style="width: 100%">
    <div class="accordion" :class="{ 'is-open': isOpen }">
      <div
        class="accordion-header-placeholder"
        :style="{ background: this.color }"
        @click="toggleAccordion"
        v-if="selected === this.default"
      >
        {{ selected }}
      </div>
      <div class="accordion-header-people" v-else>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">
            {{ selected.surname }} {{ selected.name }}
          </p>
          <p class="selected-text">ФИО</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">
            {{ selected.dateOfBirth }}
          </p>
          <p class="selected-text">Год рождения</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">КМС</p>
          <p class="selected-text">Разряд</p>
        </div>
        <div class="accordion-header-people__block">
          <p class="accordion-header-people__text">Дунаева В.C.</p>
          <p class="selected-text">Тренер</p>
        </div>
      </div>
      <div class="accordion-body items" ref="body">
        <div class="" @click="toggleAccordion" style="position: absolute;top:0;right: 0;    background: transparent;
    border-bottom: transparent;"><img src="../../assets/svg/cross.svg" alt="" style="width: 15px"></div>
        <div
          v-for="(option, i) of options"
          :key="i"
          @click="safeSelect(option), toggleAccordion()"
        >
          {{ option.name }} {{ option.surname }} / {{ option.dateOfBirth }} /
          {{ option.rank }}
        </div>
        <div class="add" @click="showModalAddSportsmen(), toggleAccordion()">
          <img src="../../assets/svg/plus.svg" alt="plus" />
          <p class="add-text">Добавить спортсмена</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      selectWithCateg: {},
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    selectIndCateg: {
      type: Object,
      // required: true,
    },
    color: {
      type: String,
    },
  },
  methods: {
    safeSelect(option) {
      this.selected = option;
      if (this.selectIndCateg) {
        this.selectWithCateg = {
          categ: this.selectIndCateg,
          sportsmen: option,
        };

        this.$emit("input", this.selectWithCateg);
        this.selected = this.default;
        this.selectWithCateg = {};
      } else {
        this.$emit("input", this.selected);
        this.selected = this.default;
      }
    },
    showModalAddSportsmen() {
      this.$emit("showModalAddSportsmen");
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.animateOpen();
      } else {
        this.animateClose();
      }
    },
    animateOpen() {
      const body = this.$refs.body;
      const height = body.scrollHeight;
      body.style.height = "0";
      setTimeout(() => {
        body.style.height = `${height}px`;
      }, 5);
    },
    animateClose() {

      const body = this.$refs.body;
      body.style.height = `${body.scrollHeight}px`;
      setTimeout(() => {
        body.style.height = "0";
      }, 5);
    },
  },
};
</script>

<style></style>
