<template>
  <transition name="modal" @click="$emit('close')">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-mini-container">
          <div class="modal-header">
            <div class="modal__cross" @click="$emit('close')">
              <img src="../../assets/svg/cross.svg" alt="cross" />
            </div>
          </div>
          <form @submit.prevent="safeInfoCoach" id="#modalModificationCoach">
            <div class="modal-body">
              <div class="modal-body__title">
                <p class="modal__title">Добавление тренера</p>
              </div>
              <div class="modal-scroll-max">
              <div class="input-wrap__column">
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="text-label">Фамилия:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putDataCoach.surname.$error,
                    }"
                    placeholder="Заполните фамилию"
                    name="surname"
                    :value="item_coach.surname"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putDataCoach.surname.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="text-label">Имя:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putDataCoach.coachName.$error,
                    }"
                    placeholder="Заполните имя"
                    name="coachName"
                    :value="item_coach.coachName"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putDataCoach.coachName.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="text-label">Отчество:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putDataCoach.patronymic.$error,
                    }"
                    placeholder="Заполните отчество"
                    name="patronymic"
                    :value="item_coach.patronymic"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putDataCoach.patronymic.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
                <div class="input-wrap__label-with-input-column">
                  <div class="input-wrap__label">
                    <p class="text-label">Клуб:</p>
                  </div>
                  <input
                    type="text"
                    class="input-light-text"
                    :class="{
                      'error-border-input':
                        isSubmitted && $v.putDataCoach.club.$error,
                    }"
                    placeholder="Организация/клуб/школа"
                    name="club"
                    :value="item_coach.club"
                  />
                  <img
                    src="~@/assets/svg/Error.svg"
                    alt="error"
                    v-if="isSubmitted && $v.putDataCoach.club.$error"
                    class="wrapValidationInput__error"
                  />
                </div>
              </div>
            </div>

            </div>
            <div class="modal-footer">
              <div class="modal-footer__button">
                <button class="button">Сохранить</button>
              </div>
              <div class="modal-footer__button">
                <button class="button-transponent" @click="$emit('close')">
                  Отменить
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { actionTypesEntered } from "@/store/modules/enteredData";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AppModalAddCoach",
  data() {
    return {
      putDataCoach: [],
      isSubmitted: false,
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.enteredData.isLoading,
    }),
  },
  props: {
    item_coach: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  validations: {
    putDataCoach: {
      surname: {
        required,
      },
      coachName: {
        required,
      },
      patronymic: {
        required,
      },
      club: {
        required,
      },
    },
  },
  methods: {
    safeInfoCoach() {
      const formData = new FormData(
        document.getElementById("#modalModificationCoach")
      );
      this.putDataCoach = [];
      for (let [name, value] of formData) {
        this.putDataCoach = { ...this.putDataCoach, [name]: value };
        console.log([name], value);
      }
      this.putDataCoach = {
        ...this.putDataCoach,
        id: this.item_coach.id,
      };
      this.$v.$touch();
      this.isSubmitted = true;
      if (this.$v.$invalid) {
        return console.log("Данные не заполенены");
      } else {
        this.$store
          .dispatch(actionTypesEntered.editCoach, this.putDataCoach)
          .then(() => {
            this.$emit("close");
          })
          .catch((er) => {
            console.log("Ошибка", er);
          });
      }
    },
    closeCoachOpenSport() {
      this.$emit("closeCoachOpenSport");
    },
  },
  mounted() {},
};
</script>

<style></style>
