<template>
  <div class="mandated-info__block">
    <div class="mandated-info__wrap">
    <div class="mandated-info__label">
      <p class="label-text-profile">
        Для просмотра спортсменов подавших заявки выберите категорию
      </p>
    </div>
    <div class="mandated-info__select">
      <custom-select-category
        @input="getSportsmen"
        :default="defaultCategory"
        :options="listNominationInd"
      />
    </div>
    </div>
    <div
      class="mandated-info__table"
      v-if="this.allSportsmens && this.allSportsmens.length !== 0"
    >
      <table class="table">
        <thead>
          <tr>
            <th class="th__name">№</th>
            <th class="th__name">Ф.И.О.</th>
            <th class="th__years">Год р.</th>
            <th class="th__section">Разряд</th>
            <th class="th__trainer">Тренеры</th>
          </tr>
        </thead>
        <tbody v-for="(sportsmen, i) in this.allSportsmens" :key="i">
          <tr>
            <td class="tb__checkbox" style="width: 5%">
              {{ i + 1 }}
            </td>
            <td class="tb__checkbox">
              {{ sportsmen.surname }} {{ sportsmen.name }}
            </td>
            <td class="tb__name">
              {{ sportsmen.dateOfBirth }}
            </td>
            <td class="tb__name">
              {{ sportsmen.rank }}
            </td>
            <td class="tb__name">
              {{ sportsmen.coaches }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="mandated-info__table"
      v-if="this.allSportsmens && this.allSportsmens.length === 0"
    >
      <p class="mandated__item-block-text-active">
        В данной категории нет заявленных спортсменов*
      </p>
    </div>
  </div>
</template>

<script>
import CustomSelectCategory from "../../components/customSelect/CustomSelectCategory.vue";
import { actionTypesMandated } from "@/store/modules/mandated";
import { mapState } from "vuex";

export default {
  components: { CustomSelectCategory },
  name: "AppInfoAboutSportsmens",
  data() {
    return {
      defaultCategory: "Выберите категорию",
    };
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.registration.isLoading,
      allSportsmens: (state) => state.mandated.allSportsmens,
    }),
  },
  props: {
    listNominationInd: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  methods: {
    getSportsmen(category) {
      // this.safeIndividualCategories = category;
      // получение спортсменов по id категории
      this.dataIdToIndSportsmen = {
        categoryId: category.id,
        competitionId: this.$route.params.id,
      };
      this.$store
        .dispatch(
          actionTypesMandated.getAllSportsmenIndCateg,
          this.dataIdToIndSportsmen
        )
        .then(() => {})
        .catch((er) => {
          console.log(er, "Error");
        });
    },
  },
};
</script>

<style></style>
